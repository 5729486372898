/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Featured',
  popular: 'Popular',
  couponsAndDeals: 'Coupons and Deals',
  yourFavorites: 'Your Favorites',
  coupons: 'Coupons',
  headerCoupons: 'Karma | Coupons',
  lastSaved: 'Last Saved',
  favorites: 'Favorites',
  trending: 'Trending',
  weAreLoving: "We're Loving",
  karmaHome: 'Karma | Home',
  goodMorning: 'Good morning',
  goodAfternoon: 'Good afternoon',
  goodEvening: 'Good evening',
  addFavoriteStores: 'Add Favorite Stores',
  nothingFound: 'Nothing found',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Save Settings',
  enableBrand: 'Enable {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Remove',
  sponsored: 'Sponsored',
  seeAll: 'See all',
  favorite: 'Favorite',
  onSale: 'On sale',
  lowInStock: 'Low in stock',
  backInStock: 'Back in stock',
  coupon: 'Coupon',
  soldOut: 'Sold Out',
  fulfilled: 'Fulfilled',
  deal: 'Deal',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: '{{count, number}} day remaining',
  dayRemaining_plural: '{{count, number}} days remaining',
  karmaStores: 'Karma Cash Stores',
  priceLeftToWithdraw: 'Only {{currency}}{{value, number}} left to go to withdraw your rewards.',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Pending Cash',
  payouts: 'Payouts',
  connectWithPayPal: 'Connect with PayPal',
  selectCause: 'Select Cause',
  dayBetweenPayouts: 'Cash can be withdrawn {{count, number}} day after purchase is confirmed.',
  dayBetweenPayouts_plural: 'Cash can be withdrawn {{count, number}} days after purchase is confirmed.',
  list: 'List:',
  emptyWallet: 'Your wallet is empty',
  startShoppingToEarn: 'Start shopping to earn rewards!',
  withdraw: 'Withdraw',
  yourDonations: 'Your donations',
  outOfAmount: 'Out of {{currency}}{{value, number}}',
  gettingCloser: "You're getting closer!",
  canWithdrawEarnings: 'You can now withdraw your earnings.',
  workedAt: 'Worked {{date}}',
  copied: 'Copied!',
  getCoupon: 'Get Coupon',
  shopNow: 'Shop Now',
  karmaLogo: 'Karma logo',
  stores: 'Stores',
  myLists: 'My Lists',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'All',
  lastWeek: 'Last week',
  lastMonth: 'Last month',
  lastThreeMonths: 'Last 3 months',
  clickID: 'Click ID:',
  date: 'Date',
  time: 'Time',
  retailer: 'Retailer',
  orderID: 'Order ID',
  startShoppingToEarnKarmaCash: 'Start shopping to earn Karma Cash,',
  browseStores: 'browse stores',
  doNotHaveClicks: "You don't have any clicks for this time period.",
  doNotHaveTransactions: "You don't have any transactions for this time period.",
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: 'Welcome bonus!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Karma Gives refund!',
  completed: 'Completed',
  notQualified: 'Not qualified',
  pending: 'Pending',
  cancelled: 'Canceled',
  paid: 'Paid',
  empty: 'Empty',
  expired: 'Expired',
  discoverAndShop: 'Discover & Shop',
  contactUs: 'Contact us',
  news: 'News',
  faq: 'FAQ',
  aboutUs: 'About us',
  karmaItem: 'Karma Item',
  email: 'Email',
  congrats: 'Congrats!',
  congratulations: 'Congratulations!',
  getKarmaButton: 'Get Karma Button!',
  saveThingsToBuy: 'Save things you want to buy later with Karma!',
  savedFirstItem: 'You have saved your first item!',
  getTheButton: 'Get the Button',
  savedFirstItem_plural: 'You have already saved a few items!',
  iWantToSeeOther: 'I want to see other <1>supported stores</1>',
  neverMissProducts: 'Never miss when products go on sale or come back in stock!',
  shareByEmail: 'Share by Email',
  whenIFindSmth: 'When I find something I want, I save it to Karma!',
  completeSignUp: 'Complete sign up',
  addToList: 'Add to list',
  chat: 'Chat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Click to save an item',
  createListAndPressEnter: "Create list and press 'Enter'",
  confirmDeleteProduct: 'Are you sure you want to delete this product?',
  shareOnFacebook: 'Share on Facebook',
  selectAll: 'Select All',
  selectProp: 'Select a {{name}}',
  delete: 'Delete',
  fulfill: 'Fulfill',
  mute: 'Mute',
  anyPriceChange: 'Any price change',
  priceOff: '{{value, number}}% off',
  notifyWhen: 'Notify When',
  tweetOnTwitter: 'Tweet on Twitter',
  shareProduct: 'Share Product',
  markAsFulfilled: 'Mark as fulfilled',
  unmuteNotifications: 'Unmute notifications',
  muteNotifications: 'Mute notifications',
  priceEvolution: 'Price Evolution',
  logOut: 'Log out',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Privacy Policy',
  confirmDelete: 'Are you sure you want to delete these products?',
  termsAndConditions: 'Terms and Conditions',
  logosProvidedBy: 'Logos provided by {{name}}',
  copyright: 'Copyright © {{year, clearNumber}} Karma Shopping Ltd., doing business as Karma. All Rights Reserved.',
  noNotifications: 'No notifications',
  clearAllNotifications: 'Clear All Notifications',
  areYouSureRemoveNotifications: 'Are you sure you want to remove all notifications?',
  deleteProducts: 'Delete products',
  no: 'No',
  yes: 'Yes',
  headerStores: 'Karma | Stores',
  clearNotification: 'Clear notification',
  clear: 'Clear',
  bell: 'bell',
  remindInMinute: 'Remind me in {{value, number}} minute',
  remindInMinute_plural: 'Remind me in {{value, number}} minutes',
  remindInHour: 'Remind me in {{value, number}} hour',
  remindInHour_plural: 'Remind me in {{value, number}} hours',
  retailers: 'Retailers',
  claimKarmaCash: 'Claim Up to {{percent, number}}% Karma Cash',
  clickOnClaimButton: 'Click the ‘Claim Up to {{percent, number}}% Karma Cash’ button.',
  goShopping: 'Go Shopping',
  rackUpCash: 'Rack Up Cash',
  getPaidDirectly: 'Get Paid Directly',
  earnRealMoney: 'Earn real money back on literally every purchase.',
  trackYourEarnings: 'Track your earnings and cash out every season.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Related Stores',
  defaultWhatHappensNext:
    'To earn Karma Cash automatically on your next purchase, click through on one of our eligible Karma Cash\n  retailers on Karma, where you can continue to shop as you usually would!\n  Remember, you can only earn Karma Cash by visiting the eligible retailer through Karma’s app or\n  dashboard (including push notifications), or by interacting with Karma browser extension on\n  desktop/mobile before purchase.\n  Once the retailer has verified your purchase, Karma will confirm your earnings in your Karma wallet.',
  defaultTermsAndConditions:
    'Karma Cash is not available on the purchase of gift cards, or on any portion of a purchase that is paid\n  for with a gift card, store credit or loyalty points or other points program. The use of promotional\n  codes not listed on the Karma website may void Karma Cash. Additional restrictions may apply to specific\n  products or categories.',
  whatHappensNext: 'What happens next?',
  about: 'About',
  readMore: 'Read more',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  loadMore: 'Load More',
  questionButton: 'question button',
  headerKarmaCash: 'Karma | Cash Rewards',
  recommended: 'Recommended',
  highToLow: 'High to Low',
  lowToHigh: 'Low to High',
  sortBy: 'Sort by:',
  exploreStores: 'Explore Stores',
  weHaveNotFoundStores: "We haven't found any stores matching your requests",
  weHaveNotFoundCoupons: "We haven't found any coupons matching your requests",
  headerApps: 'Karma | Apps',
  karmaApps: 'Get good karma, everywhere.',
  stayUpdated:
    'Stay updated across all your devices. Our digital shopping assistant lets you save items from more than {{number, number}}K stores.',
  getFaster: 'Get a faster and smoother experience by downloading Karma to your Mac.',
  headerProfile: 'Karma | Profile',
  headerWallet: 'Karma | Wallet',
  totalBalance: 'Total Balance',
  editYourAccount: 'Edit Your Account',
  emailAddress: 'Email Address',
  enterEmailAddress: 'Enter email address',
  enterEmail: 'Enter Email',
  enterEmailLower: 'Enter email',
  phoneNumber: 'Phone Number',
  enterPhoneNumber: 'Enter phone number',
  fullName: 'Full Name',
  enterFullName: 'Enter full name',
  enterPlaceholder: 'Enter {{name}}',
  birthday: 'Birthday',
  year: 'Year',
  month: 'Month',
  day: 'Day',
  gender: 'Gender',
  language: 'Language',
  currency: 'Currency',
  multiCurrency: 'Multi Currency',
  male: 'Male',
  female: 'Female',
  notWantShare: "I don't want to share",
  mobilePush: 'Mobile push',
  webPush: 'Web push',
  disconnectFromPayPal: 'Disconnect from PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Please select year and month first',
  notSureWhereToStart: 'Not sure where to start?',
  youDidntSaveItems: "You haven't save any items yet.",
  searchItems: 'Search Items',
  headerDashboard: 'Karma | Dashboard',
  headerSales: 'Karma | Sales',
  couldNotFindStores: "Oops, we couldn't find any sales",
  karmaGivesValue: '${{value, number}}',
  karmaGivesModalTitle: 'Here’s ${{value, number}} FREE to Get You Started',
  hereIsABitOfGoodKarma: 'Here’s a bit of good karma for signing up with us!',
  collectAndStartSaving: 'Collect & Start Saving',
  termsAndConditionsAppersant: 'Terms & Conditions',
  mayApply: 'may apply',
  termsAndConditionsError: 'Please agree first to our terms and privacy policy.',
  validEmailError: 'Please enter a valid email.',
  completeSignUpCapital: 'Complete Sign Up',
  or: 'OR',
  password: 'Password',
  joinUs: 'Join Us',
  reviewsPlus: '{{amount, number}}k+ reviews',
  logIn: 'Log in',
  termsAgreement: 'By signing up, you agree to our <1>Terms</1> and <3>Privacy Policy</3>.',
  termsAgreementWithCheckbox: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  createANewList: 'Create a New List',
  listCreateBlickError: "List name can't be blank",
  enterListName: 'Enter list name',
  listDescriptionOptional: 'List Description (Optional)',
  listName: 'List Name',
  enterDescriptionForList: 'Enter description for your list',
  save: 'Save',
  deleteList: 'Delete List',
  areYouSureRemoveList: 'Are you sure you want to remove this list?',
  editList: 'Edit List',
  listNameCanNotBeBlanck: "List name can't be blank",
  shareList: 'Share List',
  notAMemberCreateAccount: 'Not a member? <1>Create account!</1>',
  backToLogin: 'Back to login',
  enterYourEmail: 'Enter your email address',
  resetPassword: 'Reset password',
  forgotPasswordMessage: "In a few minutes, you'll receive an email with instructions for resetting your password.",
  forgotPassword: 'Forgot password?',
  reCaptchaMessage:
    'This site is protected by reCAPTCHA and the Google <1>Privacy Policy</1> and <3>Terms of Service</3> apply.',
  pleaseScan: 'Please scan',
  saveAnItem: 'Save an item',
  areYouSureFulfilled: 'Are you sure you want to mark as fulfilled these products?',
  muteTagDesc: 'Are you sure you want to mute notifications for these products?',
  muteTagTitle: 'Mute Notifications',
  ok: 'OK',
  canAccessEarningsInPayPal: 'You can access your earnings in your PayPal account',
  ccpaTitle: 'California Consumer Privacy Act (CCPA), as amended by the California Privacy Rights Act (CPRA)',
  ccpaText: ' By submitting this form, I exercise my right to opt-out of any sale of my personal information.',
  submit: 'Submit',
  joinKarmaAndGetRewards: 'Join Karma and Get {{stake, number}} Rewards at {{name}}',
  doNotMissOut: "Don't miss out. You may be able to save money on your item!",
  signUpAndGetMoney: 'Sign Up and Get ${{value, number}}',
  itemAlert: "You've Got an Item Alert!",
  accessKarmaCash: 'Access Your Karma Cash',
  youEarnedKarmaCash: 'You Just Earned Karma Cash!',
  verifyYourPaypalAccount: 'Verify your Paypal<1/>account',
  weHaveSentAnEmailTo: 'We’ve sent an email to',
  'Fall Favorites': 'Fall Favorites',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Clothing & Luxury',
  'Home Decor & Furniture': 'Home Decor & Furniture',
  'Electronics & Office Supplies': 'Electronics & Office Supplies',
  Luxury: 'Luxury',
  Clothing: 'Clothing ',
  'Handbags & Shoes': 'Handbags & Shoes',
  'Jewelry & Watches': 'Jewelry & Watches',
  Accessories: 'Accessories',
  'Beauty Essentials': 'Beauty Essentials',
  'Health & Wellness': 'Health & Wellness',
  Lifestyle: 'Lifestyle',
  'Sports & Outdoors': 'Sports & Outdoors',
  Travel: 'Travel',
  'Electronics & Office supplies': 'Electronics & Office supplies',
  'Entertainment & Online Services': 'Entertainment & Online Services',
  Education: 'Education',
  'Finance & FinTech': 'Finance & FinTech',
  'Pet Supplies': 'Pet Supplies',
  'Food & Beverages': 'Food & Beverages',
  Men: 'Men',
  Women: 'Women',
  Unisex: 'Unisex',
  Kids: 'Kids',
  Baby: 'Baby',
  notifications: 'Notifications',
  changesSaved: 'Changes saved!',
  headerRetailer: 'Karma | Retailer',
  headerRedirect: 'Karma | Redirecting...',
  karma: 'Karma',
  goBackToKarma: 'Back to Karma',
  authentication: 'Authentication',
  authSuccess: 'Congratulations! You have just successfully logged in.',
  authFailure: 'Oops, we could not authorize you. Try again later.',
  mayNotQualify: 'Some products may not qualify for Karma Cash',
  checkOutRetailers: 'Check out our supported Karma Cash retailers.',
  headerHowItWorks: 'Karma | How It Works',
  accept: 'Accept',
  headerError: 'Karma | Error',
  pageNotFound: 'Page Not Found',
  pageDoesNotExist: "Oops, it looks like this page doesn't exist.",
  backHome: 'Back Home',
  startShopping: 'Start Shopping',
  illustration: 'Illustration',
  dashboard: 'Dashboard',
  allRightsReserved: 'All rights reserved.',
  product: 'Product',
  disclaimer:
    'Karma Shopping Ltd., doing business as Karma. Karma does not have, nor does it purport to have, ownership of third-party trademarks. Third-party trademarks that are visible, or accessible directly or indirectly, via the Karma platform, do not necessarily have a relationship with Karma or third-parties associated with Karma or service providers of Karma.',
  help: 'Help',
  team: 'Team',
  howItWorks: 'How it works',
  supportedStores: 'Supported Stores',
  company: 'Company',
  planYourPurchase: 'Plan your next purchase with our free app!',
  itSitsInBrowser: 'It sits in your web browser while you browse all of your favorite stores.',
  seeSmthYouLike:
    'See something you like? Click the button to save it to your list and Karma will  keep an eye on its price and availability for you.',
  youWillGetNotified:
    "You'll get notified instantly when any of your saved items go on sale, drop in price, or come back in stock in your preferred size and color.",
  everythingYouWant:
    'Everything you want to buy will be in one convenient place, so you can stay organized and stop juggling multiple wishlists.',
  headerContactUs: 'Karma | Contact Us',
  needToGetInTouch: 'Need to get in touch?',
  dropALine: 'Drop Us a Line',
  comeHangOut: 'Come Hang Out',
  joinOurFacebookCommunity: 'Join Our Facebook Community',
  followOnInstagram: 'Follow Us on Instagram',
  headerAboutUs: 'Karma | About Us',
  addTheItems:
    "Add the items you're shopping for - you can customise right down to size and colour - and the app will notify you when they're on sale.",
  jonathanSay:
    'Jonathan: While retailers have been leveraging A.I. for years, it is now the consumer who will capitalize on machine learning and automation tools for efficiency.',
  justDownload:
    "Just download a 'button' and click it when you're on an item you like to save it to your list. On mobile, you can \"share\" items directly to the same lists.",
  ifYouFind:
    "If you find an item you like online that's out of your budget, you can set up a sale alert so that you're notified when the price drops.",
  onlyAHandful:
    'Only a handful of companies offering sale alerts for online members are still standing, including Karma.',
  alwaysBuy: 'Always buy something on sale with Karma.',
  headerCareers: 'Karma | Careers',
  getInTouch: 'Get in touch with us:',
  shop: 'Shop',
  weCouldNotFindResults: "We couldn't find any results for '{{name}}'",
  couldNotFindResults: "We couldn't find any results",
  shopAllStores: 'Shop all supported stores',
  popularStores: 'Popular stores',
  shops: 'Shops',
  headerDiscoverAndShop: 'Karma | Discover and shop the latest saved items',
  prev: 'Prev',
  next: 'Next',
  headerDemo: 'Karma | Demo',
  getTheKarmaButton: 'Get the Karma Button',
  installedKarma: 'You have successfully installed Karma!',
  settingUpAccount: "Now, let's get started by setting up your account.",
  signUpNow: 'Sign Up Now',
  logInNow: 'Log In Now',
  signUpLater: 'Sign up later',
  wePartnerWith: 'We partner with over {{number, number}}K+ online stores!',
  pickStoresToSee: 'Pick one of the stores below to see how it works:',
  leaveKarma: 'Note that you will leave Karma and go to the store page to get the full experience.',
  searchFavoriteStores: 'Search Your Favorite Stores',
  clickBelowToStart: 'Click below to start',
  chooseOneOfTheOptions: 'Choose one of the options below',
  clickToBegin: 'Click the search bar to begin',
  preferNotToSay: 'I prefer not to say',
  done: 'Done',
  headerUnsubscribe: 'Karma | Unsubscribe',
  changeMind: 'Change your mind?',
  resubscribeList: 'Resubscribe to your mailing list.',
  resubscribeList_plural: 'Resubscribe to your mailing list here.',
  changePreferencesAtAnyTime: 'You can also change your preferences at any time through your<1>account settings.</1>',
  preferencesUpdated: "We've updated your email preferences.",
  sorryToSeeYouGo: "We're sorry to see you go!",
  reSubscribed: "You've been re-subscribed",
  allowHours: 'Please allow up to {{hours, number}} hours for the changes to take effect.',
  missWhenYouGone:
    "We'll miss you when you're gone! Please allow up to {{hours, number}} hours for the changes to take effect.",
  headerDisablingSubscribe: 'Karma | Disabling Subscribe',
  noLongerAlerts: 'You will no longer receive alerts for this item.',
  turnAlertsBack: 'Turn alerts back on.',
  moveAnimations: 'Move button animation',
  headerExtensionDeleted: 'Karma | Extension deleted',
  areSorryToSeeYouGo: "We're sorry to see you go!",
  youHaveGotInYourWallet: "You've got ${{value}} in your Wallet.",
  toWithdraw: ' to withdraw your earnings!',
  toContinue: ' to continue earning rewards!',
  reasonUninstalled: "Please let us know why you've uninstalled:",
  getInTouchBtn: 'Get in touch',
  howWeCanIncrease: "Here's How You Can Increase Awareness and Drive Sales by Advertising with Karma",
  helpingShoppers:
    "Helping shoppers discover brands they'll love for years to come is one of our biggest priorities as a personal shopping assistant.",
  whyWeOfferBrands:
    "That's why we offer brands that consistently deliver high customer satisfaction multiple ways to connect with our audience. You get to meet your campaign goals and our audience gets to meet their new favorite brand.",
  winWin:
    'We offer brands that consistently deliver high customer satisfaction multiple ways to connect with our audience. You get to meet your campaign goals and our audience gets to meet their new favorite brand—a true win-win!',
  getFeaturedRight: 'Get featured right inside our app and browser extension dashboard',
  getFeaturedDashboard: 'Get Featured on the Karma Dashboard',
  putYourHand:
    "Put your hand, products, or promotions in the exact place our users come when they're ready to buy. We can create a variety of custom branded content that will drive warm traffic wherever you need it to go.",
  exactPlace: 'Put your brand in the exact place our users come when they’re ready to buy.',
  dedicatedInAppPlacement: 'Dedicated In-App Placement',
  dashboardFeaturedBanners: 'Dashboard Featured Banners',
  dashboardPopUpMessages: 'Dashboard Pop-Up Messages',
  customEmailsAndNewsLetters: 'Custom emails and newsletter placement to {{subscribers, number}}+ subscribers',
  customEmails: 'Custom Emails to {{subscribers, number}}+ Subscribers',
  getMoreEyes:
    'Get more eyes on your latest offer, coupon code, or product line with a dedicated email or newsletter placement to shoppers.',
  putYourBrand: "Put your brand in the exact place our users come when they're ready to buy.",
  newsletterInclusion: 'Newsletter Inclusion',
  dedicatedCustomEmails: '{{percent, number}}% Dedicated Custom Emails',
  bannerPlacementInEmails: 'Banner Placement in Notification Emails',
  pushNotificationsToShoppers: 'Push notifications to {{shoppers, number}}+ shoppers',
  pushNotificationsGiveTouchPoint:
    'Push notifications give your campaign another touch point and offer high open and click rates.',
  featureBrandClients: 'Featured Brand Clients',
  growingPerMonth: 'Growing +{{number, number}}k per month',
  members: 'Members',
  percent: '{{value, number}}%',
  kindOfReachYouExpect: 'What kind of reach can you expect?',
  ourAudience: 'Our Audience',
  tabIntoNewSource: 'Tap into a new source of qualified customers for your next campaign',
  tellUsAboutYourCompany:
    'Tell us a bit about your company’s goals and we’ll show you how together we can exceed them.',
  openAndTap: 'Open Safari and go to the product page you would like to save and tap the ‘Share’ button.',
  shareIllustration: 'Share illustration',
  tapMore: 'Tap the ‘More’ button on the right of the panel.',
  moreIllustration: 'More illustration',
  enableIllustration: 'Enable illustration',
  tapEdit: 'Tap the ‘Edit’ button at the top of the panel.',
  enableApp: 'Enable Karma and add tap the green ‘add’ button to add it to your Favourites and then tap ‘Done’.',
  goBackToTheProduct:
    'Go back to the product page, tap the ‘Share’ button again and then tap the ‘Karma’ button to save your product.',
  recommendedBy: 'Recommended by',
  ourMembersSavingMoney: "Our members ❤️ saving money with Karma. Here's why.",
  ourMembersSavingMoney2: "Our members <1/> saving money with Karma. Here's why.",
  addToBrowser: 'Add to {{browser}} - It’s Free',
  planPurchases: 'Plan your next purchases',
  karmaMakesItEasy: 'Karma makes it easy to organize your next purchases in one place.',
  buyAtRightTime: 'Buy at the right time',
  automaticallyAlert: 'Karma automatically alerts you when it’s the right time to buy.',
  cashOnVacation: 'Karma Cash on your next vacation',
  karmaPartners: 'Karma partners with many travel and hotel sites.',
  headerRewards: 'Karma | Best Coupons and Cash Rewards',
  mission: 'Our Mission',
  empowerYou: 'Empower you to make the right choices at the right time when you shop online.',
  weAreSeriousAboutPrivacy: "We're serious about your privacy and security",
  weDoNotSellData: 'We don’t sell your data',
  karmaIsSerious: 'Karma is very serious about your data and we will never sell it to any third parties. Ever.',
  karmaIsSecure: 'Karma is secure',
  weUseBankSecurity:
    'We use bank-level security ({{bit, number}}-bit encryption) to make sure your information is safe and secure.',
  helpsSaveMore: 'Karma helps you save more!',
  alertMe: 'Alert Me',
  buyWithKarmaEarn: 'Buy with Karma - Earn ${{number, number}}',
  productSaved:
    'Congratulations! This product has been saved to your items list. You will be notified when the price drops.',
  willApplyTheBestCoupons: 'Karma will automatically apply the best coupons to your purchase!',
  downloadAndAutoApply: 'Download Karma & Auto Apply',
  whatIsKarma: 'What is Karma?',
  claimUpToRewards: 'Claim up to {{number, number}}% Cash rewards',
  youMightAlsoLike: 'You might also like',
  weAreSorry: 'We are sorry, but something went wrong and page cannot be displayed.',
  tryToReloadPage: 'Try to reload the page, please.',
  Color: 'Color',
  Size: 'Size',
  latestCoupons: "{{name}} | Karma's Latest Coupons & Cashback {{year, clearNumber}}",
  neverPayFullPrice: "Never Pay Full Price. Get Karma's {{name}} Coupons & Cashback",
  neverPayFullPriceForTag: 'Never Pay Full Price for {{name}}',
  saveMoneyWhenShoppingAt: 'Save Money When Shopping at {{name}}. Join Karma For Free',
  saveMoneyWhenShoppingFor: 'Save Money When Shopping for {{name}}. Join Karma For Free',
  tagByBrand: 'Karma | {{tag}} by {{brand}}',
  typeToSearch: 'Type to search',
  getUSDWhenJoinToday: 'Get ${{value, number}} When You Join Karma Today!',
  buyProduct: 'Buy Product',
  userWishList: 'Karma | {{name}} wish list',
  byWhom: 'by',
  copiedToClipboard: 'Copied to clipboard!',
  change: 'Change',
  send: 'Send',
  cause: 'Select cause:',
  aCause: 'Select a cause:',
  close: 'Close',
  selectThisCause: 'Select this cause',
  habitatForHumanity:
    '{{name}} is focused on long-term poverty alleviation through affordable social housing, their mission is to build a safe environment where families and communities can thrive',
  casaDescription:
    'The National CASA/GAL Association, supports and promotes court-appointed volunteer advocacy so every child who has experienced abuse or neglect can be safe, have a permanent home, and the opportunity to thrive',
  endHomelessnessDescription:
    'The National Alliance to {{name}} is a nonpartisan organization committed to preventing and ending homelessness in the United States',
  pathDescription: 'PATH is ending homelessness for individuals, families, and communities',
  feedingAmericaDescription: 'Feeding America is the nation’s largest domestic hunger-relief organization',
  mealsOnWheelsDescription:
    '{{name}} empowers local community programs to improve the health and quality of life of the seniors they serve so that no one is left hungry or isolated',
  actionAgainstHunger:
    '{{name}} is a global humanitarian organization that takes decisive action against the causes and effects of hunger',
  supportMedicalAdvancement: 'Support Medical Advancement',
  saveChildsHeartDescription:
    "Save A Child's Heart mends hearts regardless of race,  religion, gender, nationality or financial status",
  su2c: 'Stand Up To Cancer (SU2C) funds and develops the newest and most promising cancer treatments to help patients today',
  drWithoutBorders: '{{name}} is An independent, global movement providing medical aid where it’s needed most',
  activeMinds:
    '{{name}} is the nation’s premier nonprofit organization supporting mental health awareness and education for young adults',
  cleanAirTaskForceDesc:
    '{{name}} pushes the change in technologies and policies needed to get to a zero-emissions, high-energy planet at an affordable cost',
  wwfDesc:
    "{{name}}'s mission is to conserve nature and reduce the most pressing threats to the diversity of life on Earth",
  waterkeepsDesc:
    '{{name}} fights for clean water. Waterkeeper Alliance works to ensure every community worldwide has drinkable, fishable, swimable water',
  xprice:
    'Every single day XPrize works to create a better world where everyone has access to clean water, nutritious food, affordable housing, effective learning, top-tier medical care and non-polluting, abundant energy',
  globalActDesc: '{{name}} works to put an end to sexual and commercial exploitation',
  pcaa: '{{name}} works hard to realize our vision of a world where all children grow up happy, healthy, and prepared to succeed in supportive families and communities',
  endCAN:
    'EndCAN raises awareness of the health, mental health and public health impacts of abuse with a mission to end child abuse and neglect',
  futuresDesc:
    'FUTURES provides groundbreaking programs, policies, and campaigns that empower individuals and organizations working to end violence against women and children around the world',
  thornDesc: '{{name} builds technology to defend children from sexual abuse.',
  'Eradicate Homelessness': 'Eradicate Homelessness',
  'Help End Hunger': 'Help End Hunger',
  'Support Medical Advancement': 'Support Medical Advancement',
  'Combat Climate Change': 'Combat Climate Change',
  'Act To End Abuse': 'Act To End Abuse',
  itemsYouSaved: 'Items You Saved For Less',
  beta: 'BETA',
  alsoAvailableAt: 'Also available at',
  atName: 'at {{name}}',
  bestPrice: 'Best Price',
  passwordWasSuccessfullyReset: 'The password was successfully reset',
  karmaIsAlwaysWithYou: 'Karma is always with you when you shop',
  saveItemsToBuy: 'Save Items and we’ll tell you the best time to buy',
  weDoTheWork: 'We do the work to find you the best coupons',
  earnRealCashThatAdds: 'Earn real cash that adds up in your wallet',
  setUpStrongPassword: 'Set up a strong password',
  settingEmailAccount:
    'The last step to saving money with Karma? Sign up so we can notify you of important alerts on your Items, like price drops.',
  settingPasswordAccount:
    'The password must be formed of a minimum of {{number, number}} characters and it shouldn’t include your email credentials.',
  paidWith: 'Paid with',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'Members',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'Monthly Visits',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'Email List',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Millenials',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Female',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'US located',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'Opt-in mobile push notifications',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: 'Will be spent by users by end of {{year, clearNumber}}',
  countWaysToUse: '{{number, number}} Ways to use Karma',
  goBack: 'Go Back',
  pleaseChooseStrongerPassword: 'Please choose a stronger password',
  pleaseEnterValidEmailAddress: 'Please enter a valid email address',
  illSignUpLater: 'I’ll sign up later',
  enterYourEmailAddress: 'Please enter your email address and we will send you a link to reset your password.',
  headerOnboardingIosExtension: 'Karma | Onboarding IOS Extension',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'We’ll automatically apply coupons wherever you shop',
  continue: 'Continue',
  changeLanguage: 'Change Language',
  selectedLanguage: 'Selected Language',
  headerMuteItem: 'Karma | Mute Item',
  itemHasBeenMuted: 'Done! This item has been muted.',
  toUnmuteItem: 'To unmute this item:',
  headToMyItems: 'Head to the ‘My Items’ tab',
  clickThreeButtonsInItem: 'Click the three buttons on the bottom right corner of the item',
  clickUnmute: 'Click ‘Unmute’',
  weveJustRaisedInFunding: 'We’ve just raised ${{value, number}}M in series A funding to help you shop smarter!',
  weveJustRaised: 'We’ve just raised ${{value, number}}M',
  landingReview1: 'Great App! I got a price drop alert and saved a lot of money!',
  landingReview2: 'Saved me ${{value, number}} on my first purchase. Very impressed!',
  landingReview3: 'It’s a beneficial app; love the notifications and rewards.',
  landingReview4: 'I have yet to experience a more seamless shopping experience!',
  landingReview5: 'I saved {{value, number}}%! I wouldn’t have known about the sale otherwise!',
  landingReview6: 'Easy to track the products you love... Highly recommend...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Reset My Password',
  repeatPassword: 'Repeat Password',
  continueAsGuest: 'Continue as Guest',
  viewAt: 'View at {{- name}}',
  signUp: 'Sign up',
  youMayAlsoLike: 'You may also like',
  buyOnShop: 'Buy on',
  viewOn: 'View on {{- name}}',
  quickView: 'Quick View',
  newList: 'New List',
  undo: 'Undo',
  productDeleted: 'Product Deleted',
  linkCopiedToClipboard: 'link copied to clipboard',
  wishlists: 'Wishlists',
  headerPartnerships: 'Karma | Partnerships',
  letsJoinForces: 'Let’s join forces',
  bePartOfTheSmartShoppingRevolution:
    'Be a part of the smart shopping revolution. We’ll get your brand in front of over {{number, number}} million shoppers who are ready to check out.',
  seeKarmaInAction: 'See Karma in action',
  discoverHowKarma:
    'Discover how Karma’s AI and automation-based solutions enhance the entire shopping journey, driving targeted traffic to your offers.',
  stepUpYourSales: 'Step up your sales',
  averageROAS: 'Average<br/>ROAS',
  increaseInAOV: 'Increase<br/>in AOV',
  useKarmaToPlanTheirNextPurchase: 'Use Karma to plan<br/>their next purchase',
  meetOurShoppers: 'Meet our shoppers',
  meetOurShoppersDescription:
    'Leverage our performance-driven marketing solutions and make your mark on shoppers with high intent to buy. Our cross-platform placements enable you to reach users wherever they are.',
  audienceInfoFirstNumber: '{{number, number}}{{unit}}+',
  audienceInfoFirstTitle: 'Users',
  audienceInfoSecondNumber: '{{number, number}}{{unit}}+',
  audienceInfoSecondTitle: 'Monthly visits',
  audienceInfoThirdNumber: '{{number, number}}{{unit}}+',
  audienceInfoThirdTitle: 'Saved items',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'US based users',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'Gen Z & millennials',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'Female shoppers',
  ourUsersLoveUs: 'Our users love us!',
  partnershipsReview1:
    'A great extension! I’ve used it for years and it’s great to be able to save lots of products in one place - it makes online shopping so much less stressful!',
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Coming from a person who does a TON of online shopping, I can honestly say that Karma is a shoppers best friend for many reasons...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    'Best Shopping App in Play Store. So easy to use. I have all my saved items at my favorite stores in one app! 👍🏻',
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Performance basis',
  fixedFee: 'Fixed fee',
  thanksForSubmitting: 'Thanks for submitting!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly: 'Someone from our partnerships team will be in touch shortly.',
  letsKickStartOurPartnership: 'Let’s kick start our partnership!',
  emailOrPhoneNumber: 'Email or phone number',
  retailerName: 'Retailer name',
  affiliateNetwork: 'Affiliate network',
  budgetStructure: 'Budget structure',
  budget: 'Budget',
  tellUsMore: 'Tell us more',
  pleaseFillInAllRequiredFields: 'Please fill in all required fields',
  partnerships: 'Partnerships',
  weCannotSaveThisItem: 'We cannot save this item <1/> as this store is not supported',
  theresCoupon: 'There’s a coupon!',
  yourItemIsOffWithCode: 'Your item is {{value, number}}% off with code {{code}} at {{- name}}.',
  muteAllNotification: 'Mute all notification',
  unMuteAllNotification: 'Unmute all notification',
  notificationSettings: 'Notification settings',
  deleteNotification: 'Delete notification',
  notificationDeleted: 'Notification deleted',
  itemMuted: 'Item muted',
  itemUnMuted: 'Item unmuted',
  priceDrop: 'Price drop',
  superDeal: '🔥 Super Deal',
  notSureWhenToBuy: 'Not sure when to buy? Create a free account and we’ll tell you when it’s deal time.',
  tcMayApply: 'T&C’s may apply',
  earnKarmaCash: 'Earn up to {{percent, number}}% Karma Cash.',
  saveToKarma: 'Save item to Karma',
  getKarmaToTrackItem: 'Get Karma to track item',
  itemSaved: 'Item Saved!',
  youCanNowFindItem: 'You can now find your item on your Karma Dashboard.',
  getKarmaExtension: 'Get the Karma extension to <1/> track this must-have',
  notifyYou: 'We’ll notify you when it’s the best time to buy!',
  downloadTheExtension: 'Download the extension',
  outOfStock: 'Out Of Stock',
  invalidEmailError: 'Invalid email',
  collapse: 'Collapse',
  expand: 'Expand',
  lists: 'Lists',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organize your items into wish lists with just a click.',
  newItem: 'New Item',
  helpCenter: 'Help Center',
  priceHighToLow: 'Price High to Low',
  priceLowToHigh: 'Price Low to High',
  sale: 'Sale',
  filterBy: 'Filter by:',
  karmaExclusive: 'K Exclusive',
  noItemsFound: 'No items found',
  select: 'Select',
  likeSneakersOrBlackFriday: 'Like ‘Sneakers’ or ‘Black Friday’',
  updateList: 'Update List',
  firstName: 'First Name',
  lastName: 'Last Name',
  enterFirstName: 'Enter first name',
  enterLastName: 'Enter last name',
  recentSearches: 'Recent searches',
  addYourFavoriteStores: 'Add your favorite stores',
  toAccessTheirLatestCoupons: 'to access their latest coupons',
  logosProvidedByClearbit: 'Logos provided by Clearbit',
  dateOfBirth: 'Date of birth',
  weHaveEmailedYouALinkToResetYourPassword: "We've emailed you a link to reset your password.",
  didNotGetIt: "Didn't get it?",
  profile: 'Profile',
  promotionsAndActivity: 'Promotions & activity',
  couponsForFavoriteStores: 'Coupons for favorite stores',
  accountActivity: 'Account activity',
  powerShopper: 'Power shopper? 💪',
  enterLinkToAnyItemPage: 'Enter a link to any item page',
  saveStuffWithZeroFuss: '<0>Get the browser extension</0> and save stuff with zero fuss.',
  viewMore: 'View more',
  tooHotToMiss: 'Too Hot To Miss',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Join over {{number, number}} million shoppers',
  waysToUse: 'Ways to use Karma',
  youCanUseKarma:
    'You can use Karma in many ways like making sure you get the best price at the right time along with planning your next purchases. Here are a few examples that we recommend:',
  livesInBrowser: 'Karma lives in your browser',
  browserExtension: 'Karma’s browser extension meets you wherever you shop across the web.',
  helpsYouPlan: 'Helps you plan your next purchases',
  notReadyToBuyJust:
    'Not ready to buy just yet? Save it for later with Karma’s browser extension and shop more mindfully. You can keep an eye on all your saved items in one place. ',
  automaticallyFindsCouponCodes: 'Automatically finds coupon codes',
  findsAndAppliesCodes:
    'Karma automatically finds and applies the best coupon for you, so you know you get the best value every time.',
  soYouCanBuyAtTheRightTime: 'So you can buy at the right time',
  usesCleverTechnologies:
    "Karma uses clever technology to track if the price drops, or when it's back in stock, so you can buy at the right time. ",
  notOnlySave:
    'Not only does Karma save you money - it also makes you money! No points. No gimmicks. You earn real cash every time you shop.',
  makeImpact: 'Make an impact!',
  donate:
    'Shop as you normally would and watch how Karma keeps giving back. For every purchase over ${{over, number}}, we donate ${{dollars, number}} to a cause of your choice.',
  accessToOffers: 'Access to exclusive offers',
  accessItems:
    'Access your saved items in one place, discover exclusive offers and see what’s trending around the world on Karma.',
  hereIsHowItWorks: 'Here’s how it works',
  learnMoreAboutGives: 'Learn more about Karma Gives',
  alwaysKnowWhenPricesDrop: 'Always know when prices drop',
  getNotifiedWhenBackInStock: 'Get notified when it’s back in stock',
  findsYouBestCoupons: 'Finds you the best coupons',
  getKarmaCashWhenYouShop: 'Get Karma Cash when you shop',
  easilySaveAllThings: 'Easily save on all your favorite things on {{value, number}}K+ stores',
  karmaProvidesGreatTechnology:
    'Karma provides you with great technology and flexibility to shop smarter, helping you avoid those unnecessary impulse buys, all while saving you time and money.',

  followOnLinkedin: 'Follow Us on LinkedIn',
  lookingForInfluencer: 'Looking for advertising partnerships?',
  skipForNow: 'Skip for now',
  noNotificationsYet: "You're all caught up",
  noNotificationsYet2: "As soon as there's a price drop or restock update, you'll find it here.",
  earnKarmaCashShort: 'Up to {{percent, number}}% K Cash',
  balance: 'Balance',
  cashOutRewards: 'Cash Out Rewards',
  connectYourPaypalAccountToCashOutRewards: 'Connect your PayPal account to cash out your rewards.',
  earned: 'Earned',
  toCashOutYouMustReach: 'To cash out your rewards, you must reach a minimum of ${{currency}}{{value, number}}.',
  areYouSureYouWantToDisconnectFromPaypal: 'Are you sure you want to disconnect from Paypal?',
  youHaveToReconnectToWithdraw: 'You’ll have to reconnect to withdraw your Karma Cash.',
  nevermind: 'Nevermind',
  yesDisconnect: 'Yes, disconnect',
  toCashOutRewards: 'To cash out your rewards, you must reach a minimum of ${{minimum, number}}.',
  congratulationsOnCashout: 'Congrats! You can now cash out your rewards.',
  stuffWorthSavingGoesHere: 'Stuff worth saving goes here 👇',
  seeHowToSave: 'See how to save',
  itsAGhostTown: `It's a ghost town`,
  addStuffYouFindToThisListToKeepItOrganized: 'Add stuff you find to this list to keep it organized.',
  checkItOffAsYouBuy: 'Check it off as you buy',
  markItemsYouveMadeYourOwnItFulfillsSoGood: `Mark items you’ve made your own. It fulfills so good!`,
  playFavesWithYourItems: 'Play faves with your items',
  starTheThingsYouNeedInYourLifeForQuickAccess: 'Star the things you need in your life for quick access.',
  yourSearchCameUpEmpty: 'Your search came up empty',
  trySearchingForSomethingElse: 'Try searching for something else.',
  freshOutCoupons: 'Fresh out of coupons',
  weHitYouUp: "We'll hit you up when we find a promo code for this store.",
  firstAddToList: 'First, add to list',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'You need to save items before you can share your list.',
  enterYourPassword: 'Enter your password',
  passwordValidationNote:
    'Must be at least 8 characters, including an uppercase letter, a lowercase letter, and a number.',
  karmaIsAnApp: 'Karma is an app and extension that helps you save time and money while shopping online.',
  addOneItemToList: 'Add 1 item to list',
  addNumberItemsToList: 'Add {{value}} items to list',
  markNumberItemsAsFullfilled: 'You’re about to mark {{value}} items as fulfilled',
  youReAboutToMuteNumberItems: 'You’re about to mute notifications for {{value}} items',
  sureYouWantToDeleteTheseNumberItems: 'Sure you want to delete these {{value}} items?',
  sureYouWantToDeleteThisList: 'Sure you want to delete this list?',
  missingYouAlready: 'Missing you already!',
  other: 'Other',
  howElseCanWeImproveKarma: 'How else can we improve Karma?',
  meetYouAtIn: 'Meet you at {{retailer}} in 3 seconds...',
  gotStuck: 'Got stuck? Click <1>here</1> to get moving.',
  weGotStuckOnOurWay: 'We got stuck on our way to {{retailer}}!',
  tryAgain: 'Try again',
  preparingYourKarmaCash: 'Prepping your Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: "Saving money is hot. Let's get on that ✌️",
  thisFieldIsRequired: 'This field is required',
  takeControlOfYourInbox: 'Take control of your inbox',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'Why unsubscribe from all our money-saving emails when you can turn off just those that aren’t relevant?',
  storePromotionsAndOtherMarketingEmails: 'Store promotions and other marketing emails',
  itemUpdatesUnsubscribe: 'Item updates (price drops, restocks, and more)',
  accountActivityAndImportantInformation: 'Account activity and important information',
  stillWannaUnsubscribe: 'Still wanna <1>unsubscribe from all Karma emails</1>?',
  alrightBut: 'Alright, but you’re in for some major fomo!',
  updatePreferences: 'Update preferences',
  connectToPayPalToCashOut: 'Connect a PayPal account to cash out your rewards',
  weAreBusySelfImproving: 'We’re busy self-improving',
  sorryForInconvenience: "Sorry for the inconvenience - we promise it'll be worth it.",
  search: 'Search',
  dropSomeShoppingInspo: 'Drop some shopping inspo on your fam.',
  orCopyTheLinkBelowAndSend: 'Or, copy the link below and send it to them old-school style.',
  joinTheTeam: 'Join the team',
  info: 'Info',
  gotIt: 'Got it',
  wereHereToMakeItYours: 'We’re here to <1>#make_it_yours</1>',
  lookAtYouLittleScroller: 'Look at you, you little scroller. Kudos for getting this far.',
  nowLetsTalkAboutPrivacy: 'Now let’s talk privacy 🤫',
  weUseBankLevelSecurity: 'We use bank-level security',
  yourInformationIsSecured: 'Your information is secured using the latest 256-bit encryption for complete protection.',
  weDontSellYourData: 'We don’t sell your data',
  weAreVerySeriousAboutPrivacy:
    'We’re very serious about privacy and will never sell your data to any third parties. Ever.',
  soFarWeSavedYou: 'So far, we’ve saved you',
  happyShoppers: '👉 {{count, number}}M+ happy shoppers',
  starReviews: '👉 {{count, number}}K+ 4.7 star reviews',
  storesWorldwide: '👉 {{count, number}}K+ stores worldwide',
  wellTrackAndScanForCoupons:
    'We’ll track and scan for coupons so you don’t have to. <br/> (ok we won’t, but our AI will)',
  lolWereAVerbNow: '(lol we’re a verb now)',
  letsPlay: 'Let’s play',
  noFomoHere: 'No fomo here. We’ll give you a buzz when your ‘wishes’ drop in price or come back in stock.',
  never: 'Never',
  missOut: 'miss out',
  cashIsBack: '<1>Cash</1> is back',
  cashIsBackColoredRight: 'Cash is <1>back</1>',
  withKarmaYourCash: 'With Karma, your cash comes back to you like some sort of voodoo, magic money.',
  getIt: 'Get it',
  wantIt: 'Want it',
  wereGoingShopping: 'We’re going shopping',
  knowHowToShopBetter: 'Know how to <1>shop better</1> than everyone else',
  addKarmaToYourDesktop: 'Add Karma to your desktop so you can shop<br/>during work hours.',
  yesIDoThat: 'Yes, I do that',
  iNeedThisInMyLife: 'I need this in my life',
  payLess: 'Pay less',
  money: 'money',
  theMotherOfAllWishLists: 'The mother of all <1>wish lists<1/>',
  nowLetsTalPrivacy: 'Now let’s talk <1>privacy<1/>',
  withKarmaYouCanBuy: 'With Karma you can buy<br />whatever you want and keep<br />the bank lady happy.',
  youReATen:
    'You’re a 10 and you pay with Karma? Shop online and get the lowest possible price every 👏 single 👏 time.',
  karmaYourFavoriteBrands: '<1>Karma</1> your favorite brands',
  buyWhateverYouWant: 'Buy whatever you want and keep the bank lady happy.',
  onlineShoppingButBetter: 'Online shopping but <1>better</1>',
  downloadOurApp: 'Download our app and take <1>Karma to-go</1>',
  getTheApp: 'Get the app',
  karmaGetsYouTheLowestPossiblePrice: 'Karma gets you the lowest possible price every 👏 single 👏 time.',
  weHustleForCoupons: 'We hustle for <1>coupons</1>',
  wellTrackAndScanForCouponsMobile: 'We’ll track and scan for coupons so you don’t have to.',
  wellApplyCodesThatSaveYouMoney: 'We’ll apply codes that save you $$$.',
  getBuzzedWhenYourWishes: 'Get buzzed 🐝 when your ‘wishes’ drop in price or come back in stock.',
  yourCashWillComeBackToYou: 'Your cash will come back to you like some sort of voodoo, magic money. ',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> your favorite brands',
  lookAtYouLittleScrollerMobile: 'Look at you, you little scroller.',
  wereAVerbNowLol: '(we’re a verb now lol)',
  total: 'Total',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail: 'Keep an eye out for the status updates we’ll send to {{email}}.',
  psaDidYouKnow: 'PSA: Did you know that you can',
  addWishItemsFromAnyBrand: 'Add ‘wish’ items from any brand and hang out until we say it’s time to get them.',
  capturePurchaseReadyShoppers: 'Capture purchase-ready shoppers by showcasing your hottest coupons.',
  gainFullFunnelInsights: 'Gain full-funnel insights into buyer intent with every item saved.',
  boostCartEngagement: 'Boost cart engagement with our real-time item updates.',
  promoteLongTermGrowth: 'Promote long-term growth through tailored campaigns within the Karma ecosystem.',
  reachNewAudiences: 'Reach new audiences through diverse campaigns tailored to your brand',
  influencerCollaborations: 'Influencer collaborations',
  teamUpWithTopInfluences: 'Team up with top influencers that best match your brand’s identity.',
  socialPosts: 'Social posts',
  giveANewFollowing: 'Gain a new following from bespoke, content-rich campaigns.',
  brandFocusedNewsletters: 'Brand-focused newsletters',
  letYourOffersShine: 'Let your offers shine in highly anticipated curated emails.',
  driveTrafficWhenYouNeedIt: 'Drive traffic where you need it to go with our shoppable homepage placements',
  dashboardBanners: 'Dashboard banners',
  flauntYourLatestOfferings: 'Flaunt your latest offerings with eye-catching visuals.',
  dailyFeatured: 'Daily featured',
  highlightDeals: 'Highlight deals where it matters most.',
  dedicatedPopUps: 'Dedicated pop-ups',
  activelyServeYourPromos: 'Actively serve your promos so they can’t be missed.',
  createAnotherTouchpointForYourBrand: 'Create another touchpoint for your brand',
  increaseEngagement: 'Increase engagement and conversion rates through targeted push notifications on mobile and web.',
  karmaIsOnlineShoppingButBetter: 'Karma is online shopping, but better.',
  transactionsNumber: '👉 {{count, number}}M+ transactions',
  partnershipsReview4: 'This is fantastic for making your wish list and saving money!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'It’s literally the best extension I’ve ever downloaded. It’s the best for saving money and getting money at the same time!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'No need to slide into our DM’s (you could though 😏). Our team is here for whatever you need.',
  supportIssues: 'Support issues',
  adsAndPartnerships: 'Ads & Partnerships',
  jobOpportunities: 'Job opportunities',
  publicRelations: 'Public relations',
  letsChat: 'Let’s chat',
  earnings: 'Earnings',
  allTime: 'All time',
  ordersMayTakeHoursToAppear: 'Orders may take up to {{value, number}} hours to appear.',
  youHaventReceivedAnyPayoutsYet: 'You haven’t received any payouts yet ',
  alerts: 'Alerts',
  featuresReview1:
    'I added a pair of sneakers to my wishlist and literally the next day got a ping that it was 50% off. How did I even buy anything before you guys?',
  featuresReviewAuthor1: 'Sandra C.Martinez, App store reviewer',
  featuresReview2:
    'This is seriously the best smart shopping app and I know of a few others. I actually feel like I’m cheating the system a little bit because I’m saving actual money! I always used to pay full and feel like such a sucker',
  featuresReviewAuthor2: 'Gertie D. Ackerman, App store reviewer',
  featuresReview3:
    'I was shopping for a new laptop that was already 25% off. When I hit ‘pay with Karma’, I noticed that the total was even less. This happens every time I pay with the app',
  featuresReviewAuthor3: 'Irene J. Cox, App store reviewer',
  downloadNow: 'Download now',
  wannaBeDeskBuddies: 'Wanna be desk buddies?',
  seeOpenPositions: 'See open positions',
  weLiveAndSwearBy: 'We live and swear by...',
  goodKarma: 'Good Karma',
  weBelieveThatGoodVibes: 'We believe that ~good vibes~ are a lifestyle and we’re sticking to it.',
  beingTrue: 'Being true',
  theresNoUSvsU: 'There’s no US vs U. Our tables are always round and we’re saving you a seat.',
  passion: 'Passion',
  weHaveThatFireYouKnow: 'We have that fire, u know? We operate on drive, persistence, and love for what we do.',
  entrepreneurship: 'Entrepreneurship',
  weWantItWeGetIt: 'We want it - we get it. We don’t have any time to waste on endless Zooms. We go for what we want.',
  mutuality: 'Mutuality',
  wereMadeUpOfAwesomePeople:
    'We’re made up of awesome people who understand that teamwork is not enough. We actually care for each other.',
  buildCustomerLoyalty: 'Build customer loyalty with instant cash back',
  itemSaving: 'Item saving',
  itemUpdates: 'Item updates',
  backInStockAlerts: 'Back in stock',
  lowInStockAlerts: 'Low in stock',
  storePromotions: 'Store promotions',
  exclusive: 'Exclusive',
  couponCopied: 'Coupon copied',
  completedDesc:
    'The store has confirmed your order. Any Karma Cash earned can be withdrawn {{days, number}} days from date of purchase and only after you’ve reached the {{currency}}{{amount, number}} minimum.',
  notQualifiedDesc: 'Additional restrictions may apply to the items you’ve ordered.',
  pendingDesc:
    'The store has received your order. It may show as pending for up to {{days, number}} days, depending on the store’s return policy.',
  cancelledDesc: 'The store has informed us that your order was either canceled or refunded in full.',
  downloadNowItsFree: 'Download now, it’s free',
  weLiveToMakeOurShoppersHappy: 'We live to make our shoppers happy',
  moneySavedForYou: 'Money saved for you',
  moneySavedForUsers: 'Money saved for users',
  happyShoppers2: 'Happy shoppers',
  starReviews2: '{{value, number}} star reviews',
  storesWorldwide2: 'Stores worldwide',
  whyYouWill: 'Why you’ll',
  it: 'it',
  multiBrandWishLists:
    'Multi-brand wish lists? Legit chills. Spot something you love from any brand, add it to your Karma list, and hang out until we say it’s time to  get them.',
  addKarmaToYourMobile: 'Download<br />our app<br />and take<br />Karma to-go',
  appStoreReview1:
    'I added an eyeshadow palette to my list and the next day got a notification that it was 50% off. Thank you!!',
  appStoreReview2: 'The fact that the lowest price is always right in my pocket is so dangerous. But also I love it',
  appStoreReview3: 'Just scored the lowest price on new headphones while waiting for the bus. It’s just too easy',
  appStoreReviewBy: 'App store review by: {{name}}',
  yourInfoSafeWithUs: 'Your info is safe with us',
  whyGiveOutYourCCDeets: 'Why give out your CC deets when you can keep it in one place?',
  partyForOne: 'Party for 1',
  wellNeverSellYourDataToAnyThirdParties:
    'We’ll never sell your data to any third parties, period. With bank-level security, your data is safe from prying eyes.',
  buyWithoutATrace: 'Buy without a trace',
  weWontLetAnyoneTrackYourShoppingHabits: 'We won’t let anyone track your shopping habits. There’s no judgment here.',
  spotSomethingYouLoveFromAnyBrand:
    'Spot something you love from any brand, add it to your Karma list, and hang out until we say it’s time to get them.',
  needIt: 'Need it',
  appStoreReviewer: 'App Store reviewer',
  appStoreReview4:
    'I rely on this app so much to keep track of all my "want" items in one place to get the at the best price possible.',
  appStoreReview5: 'This app has helped me save so much money.',
  wishlist: 'Wish list',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Get the lowest prices at all your favorite brands.',
  getTheAppForFree: 'Get the app for free',
  addKarmaSpaced: 'Add Karma,<br />to your desktop<br />so you can shop<br />during work hours.',
  spotSomethingYouLoveFromAnywhere:
    'Spot something you love from anywhere, add it to your Karma list, and hang out until we say it’s go time.',
  getCouponsCashbackWithPeriod: 'Get coupons, cash back, and price drop alerts.<br />Always pay the lowest price.',
  getCouponsCashbackWithAnd: 'Get coupons, cash back, price drop alerts<br />and always pay the lowest price.',
  clickTheLoginLinkFromYourPhone: 'Click the login link from your phone to access the Karma app.',
  getItOn: 'Get it on',
  downloadOnThe: 'Download on the',
  likeShoppingFromYourComputer: 'Like shopping from your computer?',
  getTheKarmaExtensionToShopEvenBetter: 'Get the Karma extension to shop even better.',
  headerMobileLogin: 'Karma | Install',
  noFomoHereMobile: 'No fomo here.\nWe’ll give you a buzz when\nyour ‘wishes’ drop in price\nor come back in stock.',
  withKarmaYourCashMobile: 'With Karma, your cash\ncomes back to you\nlike some sort of voodoo,\nmagic money.',
  spotSomethigYouLoveMobile:
    'Spot something you love\nfrom anywhere, add it to your\nKarma list, and hang out until\nwe say it’s go time.',
  wellTrackAndScanForCouponsMobile2:
    'We’ll track and scan\nfor coupons so you don’t have to.\n(ok we won’t, but our AI will)',
  weHustle: 'We hustle',
  forCoupons: 'for <1>coupons</1>',
  theMotherOfAll: 'The mother of all',
  wishlists2: 'wish lists',

  // NEW PHRASES
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  postCode: 'Postcode',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by finding the best benefits and rewards at checkout',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOn: 'Enable Karma on Safari',
  toAccessOurCouponScanner: 'to access our coupon scanner',
  alwaysAllowOnSites: '<0>Always allow</0> on all sites to get\naccess to the coupon scanner',
  dontSeeReview: 'I don’t see a “Review” button',
  iosOnboardingFlowYouNeedToUseSafari: 'You need to be in Safari to use the Karma extension',
  iosOnboardingFlowCopyTheLink: 'Copy the link below and paste it in Safari to continue.',
  iosOnboardingCopyLink: 'Copy link',
  iosOnboardingLinkCopied: 'Link copied',
  tapExtensionThenSelect: 'extension, then <1>select both middle options</1>',
  alwaysAllow: 'Always Allow',
  alwaysAllowOnEveryWebsite: 'Always Allow on Every Website',
  clickInTheAddressBar: 'Click<1></1>or',
  activate: 'Activate',
  grantAccess: 'Grant access',
  sweOnboardingStep1_1_1: 'Tap <1></1> or',
  sweOnboardingStep1_1_2: 'in the address bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the address bar',
  sweOnboardingStep1_2: 'Select <1>“Manage Extensions”<1/>',
  sweOnboardingStep1_3: 'Turn on <1>“Karma”</1>',
  sweOnboardingStep2_1_old: 'Tap the <1>A</1><2>A</2> in the address bar again',
  sweOnboardingStep2_2_old: 'Tap the <1></1> extension, then <3>select both middle options</3>',
  sweOnboardingStep2_3_old: 'Select “Always allow”',
  sweOnboardingStep2_4_old_1: 'Select “Always allow”',
  sweOnboardingStep2_4_old_2: '“On every Site”',

  sweOnboardingStep2_5_old_1: 'Go back to the',
  sweOnboardingStep2_5_old_2: 'Karma app',
  sweOnboardingStep2_1: 'Tap <1>“Review”</1> in the top right corner, then <3>select both middle options</3>',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
  yourDataHasBeenRemoved: 'Your data has been successfully removed.',
  confirmationCode: 'Confirmation code: {{code}}',
  startOnlineShopping: 'Start online shopping to see Karma in action',
  trendingOffers: 'Trending Offers',
  useCode: 'Use code: {{code}}',
  getDeal: 'Get Deal',
  buyNow: 'Buy now',
  trendingCoupons: 'Trending Coupons',
  searchResultsFor: 'Search Results for "{{search}}"',
  trendingItems: 'Trending Items',
  curateSmartWishlists: 'Curate Smart wishlists',
  karmaWillLetYouKnow: 'Karma will let you know when the items you want go on sale or are back in stock',
  saveEarnAtCheckout: 'Save & Earn at checkout',
  weApplyBestCoupon:
    'We’ll automatically apply the best coupon codes on the web at checkout & reward for your purchase on 1000’s of stores',
  welcomeTo: 'Welcome to',
  youAreOneStepCloser:
    'You’re one step closer to saving money\nand shopping smarter.\nGo to your favorite store and we’ll see you there!',
  startBrowsing: 'Start browsing',
  scanToGetApp: 'Scan to get the app',
  unlockYourCreditCardsBenefits: 'Unlock your credit cards benefits',
  easilyCompareBetweenCreditCards:
    'Easily compare between the credit cards rewards to choose the best one to use at checkout',
};
