/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'चित्रित',
  popular: 'प्रचलित',
  couponsAndDeals: 'कूपन और डील',
  yourFavorites: 'आपके पसंदीदा',
  coupons: 'कूपन',
  headerCoupons: 'Karma | कूपन',
  lastSaved: 'पिछली बार सेव किया गया',
  favorites: 'पसंदीदा',
  trending: 'ट्रेंडिंग',
  weAreLoving: 'हमें पसंद आ रहा है',
  karmaHome: 'Karma | होम',
  goodMorning: 'गुड मॉर्निंग',
  goodAfternoon: 'गुड आफ़्टरनून',
  goodEvening: 'गुड इवनिंग',
  addFavoriteStores: 'पसंदीदा स्टोर जोड़ें',
  nothingFound: 'कुछ नहीं मिला',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'सेटिंग सहेजें',
  enableBrand: 'सक्रिय {{name}}',
  chrome: 'क्रोम',
  edge: 'सिरा',
  firefox: 'फायरफॉक्स',
  ie: 'इंटरनेट एक्सप्लोरर',
  oldEdge: 'सिरा',
  opera: 'ओपेरा',
  safari: 'सफारी',
  remove: 'हटाएं',
  sponsored: 'प्रायोजित',
  seeAll: 'सभी देखें',
  favorite: 'पसंदीदा',
  onSale: 'सेल पर उपलब्ध',
  lowInStock: 'स्टॉक में कम',
  backInStock: 'स्टॉक में वापस',
  coupon: 'कूपन',
  soldOut: 'माल खतम',
  fulfilled: 'पूर्ण',
  deal: 'सौदा',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: '{{days, number}} दिन शेष',
  dayRemaining_plural: '{{days, number}} दिन शेष',
  karmaStores: 'Karma Cash स्टोर्स',
  priceLeftToWithdraw: 'इनाम प्राप्त करने हेतु केवल {{currency}}{{value, number}} शेष हैं!',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'लंबित कैश',
  payouts: 'भुगतान',
  connectWithPayPal: 'PayPal के साथ जुड़ें',
  selectCause: 'उद्देश्य चुनें',
  dayBetweenPayouts: 'खरीद पुष्टि के {{count, number}} दिन बाद नकद निकाला जा सकेगा।',
  dayBetweenPayouts_plural: 'खरीद पुष्टि के {{count, number}} दिनों बाद नकद निकाला जा सकेगा।',
  list: 'सूची:',
  emptyWallet: 'आपका वॉलेट खाली है',
  startShoppingToEarn: 'पुरस्कार पाने हेतु खरीदारी करें!',
  withdraw: 'निकालें',
  yourDonations: 'आपकी दान राशियां',
  outOfAmount: '{{currency}}{{value, number}} खतम',
  gettingCloser: 'आप करीब पहुंच रहे हैं!',
  canWithdrawEarnings: 'अब आप अपनी कमाई निकाल सकते हैं।',
  workedAt: 'कार्य {{date}}',
  copied: 'कॉपी हो गया!',
  getCoupon: 'कूपन प्राप्त करें',
  shopNow: 'अभी खरीदारी करें',
  karmaLogo: 'Karma लोगो',
  stores: 'स्टोर',
  myLists: 'मेरी सूचियां',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'सभी',
  lastWeek: 'पिछले सप्ताह',
  lastMonth: 'पिछले महीने',
  lastThreeMonths: 'पिछले तीन महीने',
  clickID: 'क्लिक आईडी:',
  date: 'दिनांक',
  time: 'समय',
  retailer: 'खुदरा विक्रेता',
  orderID: 'ऑर्डर आईडी',
  startShoppingToEarnKarmaCash: 'Karma Cash अर्जन हेतु खरीदारी शुरू करें,',
  browseStores: 'स्टोर्स देखें',
  doNotHaveClicks: 'इस समयावधि के लिए आपके कोई क्लिक नहीं हैं।',
  doNotHaveTransactions: 'इस समयावधि में आपका कोई लेन-देन नहीं है।',
  payPalAlt: 'पेपाल (PayPal)',
  payPal: 'पेपाल:',
  welcomeBonus: 'स्वागत बोनस!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Karma Gives प्रतिदाय!',
  completed: 'पूरा हुआ',
  notQualified: 'अयोग्य',
  pending: 'लंबित',
  cancelled: 'रद्द',
  paid: 'भुगतान किया गया',
  empty: 'खाली',
  expired: 'एक्स्पायर्ड',
  discoverAndShop: 'खोजें और खरीदें',
  contactUs: 'संपर्क करें',
  news: 'समाचार',
  faq: 'एफएक्यू',
  aboutUs: 'हमारे बारे में',
  karmaItem: 'Karma आइटम',
  email: 'ईमेल',
  congrats: 'बधाईयां!',
  congratulations: 'बधाई हो!',
  getKarmaButton: 'Karma बटन पाएं !',
  saveThingsToBuy: 'चीजें सहेजें जिन्हें आप बाद में Karma से खरीदेंगे!',
  savedFirstItem: 'आपने अपना पहला आइटम सहेजा है!',
  getTheButton: 'बटन प्राप्त करें',
  savedFirstItem_plural: 'पहले से आपने कुछ आइटम सहेजे हैं!',
  iWantToSeeOther: 'मैं अन्य <1>समर्थित स्टोर</1>देखना चाहता हूं',
  neverMissProducts: 'उत्पाद जब सेल या स्टॉक में हों इससे कभी न चूकें!',
  shareByEmail: 'ईमेल पर साझा करें',
  whenIFindSmth: 'जब मुझे वह मिलता है जो मैं चाहता हूं, तो मैं उसे Karma में सेव कर लेता हूं!',
  completeSignUp: 'साइन अप पूरा करें',
  addToList: 'सूची में शामिल करें',
  chat: 'चैट',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'आइटम सहेजने के लिए क्लिक',
  createListAndPressEnter: "सूची बनाएं और 'एंटर' दबाएं",
  confirmDeleteProduct: 'क्या आप वाकई इस उत्पाद को हटाना चाहते हैं?',
  shareOnFacebook: 'Facebook पर साझा करें',
  selectAll: 'सभी चुनें',
  selectProp: 'एक {{name}} चुनें',
  delete: 'हटाएं',
  fulfill: 'पूरा',
  mute: 'म्यूट',
  anyPriceChange: 'मूल्य में कोई परिवर्तन',
  priceOff: '{{value, number}}% की छूट',
  notifyWhen: 'सूचित करें जब',
  tweetOnTwitter: 'Twitter पर ट्वीट करें',
  shareProduct: 'उत्पाद साझा करें',
  markAsFulfilled: 'पूर्ण के रूप में चिह्नित करें',
  unmuteNotifications: 'सूचनाएं अनम्यूट करें',
  muteNotifications: 'सूचनाएं म्यूट करें',
  priceEvolution: 'मूल्य विकास',
  logOut: 'लॉग आउट',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'इंस्टाग्राम',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'गोपनीयता नीति',
  confirmDelete: 'क्या आप वाकई इन उत्पाद को हटाना चाहते हैं?',
  termsAndConditions: 'नियम और शर्तें',
  logosProvidedBy: '{{name}} द्वारा प्रदान लोगो',
  copyright: 'कॉपीराइट ©{{year, clearNumber}} Karma Shopping Ltd., Karma के रुप में व्यापार। सभी अधिकार आरक्षित।',
  noNotifications: 'कोई सूचनाएं नहीं',
  clearAllNotifications: 'सभी सूचनाएं हटाएं',
  areYouSureRemoveNotifications: 'क्या आप वाकई सभी सूचनाएं हटाना चाहते हैं?',
  deleteProducts: 'उत्पाद हटाएं',
  no: 'नहीं',
  yes: 'हां',
  headerStores: 'Karma | स्टोर्स',
  clearNotification: 'सूचना हटाएं',
  clear: 'मिटाएं',
  bell: 'बेल',
  remindInMinute: 'मुझे {{value, number}} मिनट में याद दिलाएं',
  remindInMinute_plural: 'मुझे {{value, number}} मिनट में याद दिलाएं',
  remindInHour: 'मुझे {{value, number}} घंटे में याद दिलाएं',
  remindInHour_plural: 'मुझे {{value, number}} घंटों में याद दिलाएं',
  retailers: 'खुदरा विक्रेता',
  claimKarmaCash: '{{percent, number}}% तक Karma Cash का दावा करें',
  clickOnClaimButton: "'{{percent, number}}% तक Karma Cash का दावा करें' बटन पर क्लिक करें।",
  goShopping: 'खरीदारी करें',
  rackUpCash: 'कैश एकत्र करें',
  getPaidDirectly: 'सीधे भुगतान प्राप्त करें',
  earnRealMoney: 'अपनी हर खरीदारी पर सचमुच में कुछ पैसा वापस प्राप्त करें।',
  trackYourEarnings: 'अपनी कमाई को ट्रैक करें और हर सीजन में कैश आउट करें।',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'संबंधित स्टोर',
  defaultWhatHappensNext:
    'अगली खरीदारी पर स्वचालित रूप से Karma Cash कमाने हेतु अपनी खरीदारी की नियमित जगह पर हमारे Karma Cash विक्रेताओं में से एक पर क्लिक करें! याद रखें, आप Karma ऐप या डैशबोर्ड (पुश नोटिफिकेशन सहित) के माध्यम से रिटेलर के पास जाकर, या खरीदारी पूर्व डेस्कटॉप/मोबाइल पर Karma ब्राउज़र एक्सटेंशन की मदद से Karma Cash कमा सकते हैं। रिटेलर द्वारा आपकी खरीदा सत्यापित करने के बाद आपके Karma वॉलेट में कमाई की पुष्टि कर दी जाएगी।',
  defaultTermsAndConditions:
    'गिफ्ट कार्ड की खरीद, या खरीदारी के ऐसे किसी हिस्से पर Karma Cash उपलब्ध नहीं है, जिसका भुगतान गिफ्ट कार्ड, स्टोर क्रेडिट या लॉयल्टी पॉइंट, या अन्य पॉइंट प्रोग्राम से किया जाता है। Karma वेबसाइट पर गैर-सूचीबद्ध प्रचार कोड का उपयोग Karma Cash रद्द कर सकता है। विशिष्ट उत्पादों, श्रेणियों पर अतिरिक्त प्रतिबंध लागू हो सकते हैं।',
  whatHappensNext: 'आगे क्या होगा?',
  about: 'बारे में',
  readMore: 'अधिक पढ़ें',
  frequentlyAskedQuestions: 'एफएक्यू',
  loadMore: 'अधिक लोड करें',
  questionButton: 'प्रश्न बटन',
  headerKarmaCash: 'Karma | नकद इनाम',
  recommended: 'अनुशंसित',
  highToLow: 'अधिक से कम',
  lowToHigh: 'कम से अधिक',
  sortBy: 'द्वारा क्रमबद्ध:',
  exploreStores: 'स्टोर्स खोजें',
  weHaveNotFoundStores: 'आपके अनुरोधों से मिलता कोई स्टोर नहीं मिला',
  weHaveNotFoundCoupons: 'आपके अनुरोधों से मिलता कोई कूपन नहीं मिला',
  headerApps: 'Karma | ऐप्स',
  karmaApps: 'अच्छे Karma पाएं, हर जगह।',
  stayUpdated:
    'अपने सभी उपकरणों में अपडेट रहें। हमारा डिजिटल शॉपिंग सहायक {{number, number}}K स्टोर्स से अधिक से आइटम सहेजने देता है।',
  getFaster: 'अपने मैक पर Karma डाउनलोड करके एक तेज और आसान अनुभव पाएं।',
  headerProfile: 'Karma | प्रोफाइल',
  headerWallet: 'Karma | वॉलेट',
  totalBalance: 'कुल शेष',
  editYourAccount: 'खाता संपादित करें',
  emailAddress: 'ईमेल पता',
  enterEmailAddress: 'ईमेल पता दर्ज करें',
  enterEmail: 'ईमेल डालें',
  enterEmailLower: 'ईमेल डालें',
  phoneNumber: 'फोन नंबर',
  enterPhoneNumber: 'फोन नंबर डालें',
  fullName: 'पूरा नाम',
  enterFullName: 'पूरा नाम दर्ज करें',
  enterPlaceholder: '{{name}} डालें',
  birthday: 'जन्मदिन',
  year: 'साल',
  month: 'महीना',
  day: 'दिन',
  gender: 'लिंग',
  language: 'भाषा',
  currency: 'मुद्रा',
  multiCurrency: 'बहु मुद्रा',
  male: 'पुरुष',
  female: 'महिला',
  notWantShare: 'मैं साझा नहीं करना चाहता',
  mobilePush: 'मोबाइल पुश',
  webPush: 'वेब पुश',
  disconnectFromPayPal: 'PayPal से डिस्कनेक्ट करें',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'कृपया पहले वर्ष और महीना चुनें',
  notSureWhereToStart: 'कहां से शुरू करें नहीं पता?',
  youDidntSaveItems: 'आपने अभी तक कोई आइटम सेव नहीं किया है।',
  searchItems: 'आइटम खोजें',
  headerDashboard: 'Karma | डैशबोर्ड',
  headerSales: 'Karma | सेल्स',
  couldNotFindStores: 'ओह, हमें कोई बिक्री नहीं मिली',
  karmaGivesValue: '${{value, number}}',
  karmaGivesModalTitle: 'आपकी शुरूआत के लिए ${{value, number}} मुफ्त',
  hereIsABitOfGoodKarma: 'ये रहे हमारे साथ साइन-अप के लिए कुछ अच्छे Karma!',
  collectAndStartSaving: 'लें और बचाना शुरू करें',
  termsAndConditionsAppersant: 'नियम और शर्तें',
  mayApply: 'लागू हो सकता है',
  termsAndConditionsError: 'कृपया पहले हमारी शर्तों और गोपनीयता नीति से सहमति प्रदान करें।',
  validEmailError: 'कृपया एक मान्य ईमेल डालें।',
  completeSignUpCapital: 'साइन अप पूरा करें',
  or: 'या',
  password: 'पासवर्ड',
  joinUs: 'हमसे जुड़ें',
  reviewsPlus: '{{amount, number}}k+ समीक्षाएं',
  logIn: 'लॉग इन करें',
  termsAgreement: 'साइन अप करके, आप हमारी <1>शर्तों</1> और <3>गोपनीयता नीति</3>से सहमत हैं',
  termsAgreementWithCheckbox: 'मैं <1>नियम और शर्तें</1> और <3>गोपनीयता नीति< / 3>से सहमत हूं',
  createANewList: 'एक नई सूची बनाएं',
  listCreateBlickError: 'सूची नाम रिक्त नहीं रह सकता',
  enterListName: 'सूची नाम डालें',
  listDescriptionOptional: 'सूची विवरण (वैकल्पिक)',
  listName: 'सूची नाम लॉगिन करें',
  enterDescriptionForList: 'अपनी सूची के लिए विवरण दर्ज करें',
  save: 'सेव करें',
  deleteList: 'सूची हटाएं',
  areYouSureRemoveList: 'क्या आप वाकई इस सूची को हटाना चाहते हैं?',
  editList: 'सूची संपादित करें',
  listNameCanNotBeBlanck: 'सूची नाम रिक्त नहीं रह सकता',
  shareList: 'सूची साझा करें',
  notAMemberCreateAccount: 'सदस्य नहीं हैं?<1>खाता बनाएं!</1>',
  backToLogin: 'लॉगिन पर वापस',
  enterYourEmail: 'अपना ईमेल पता दर्ज करें',
  resetPassword: 'पासवर्ड रीसेट',
  forgotPasswordMessage: 'कुछ ही मिनटों में आपको अपना पासवर्ड रीसेट करने के निर्देशों के साथ एक ईमेल प्राप्त होगी।',
  forgotPassword: 'पासवर्ड भूल गए?',
  reCaptchaMessage: 'यह साइट रीकैप्चा और गूगल द्वारा संरक्षित है<1>गोपनीयता नीति</1> और <3>सेवा शर्तें</3> लागू',
  pleaseScan: 'स्कैन करें',
  saveAnItem: 'कोई आइटम सेव करें',
  areYouSureFulfilled: 'क्या आप वाकई इन उत्पादों को पूर्ण चिन्हित करना चाहते हैं?',
  muteTagDesc: 'आप वाकई इन उत्पादों के लिए सूचनाएं म्यूट करना चाहते हैं?',
  muteTagTitle: 'सूचनाएं म्यूट करें',
  ok: 'ठीक है',
  canAccessEarningsInPayPal: 'आप अपने PayPal खाते में अपनी कमाई को एक्सेस कर सकते हैं',
  ccpaTitle:
    'कैलिफोर्निया गोपनीयता अधिकार अधिनियम (सीपीआरए) द्वारा संशोधित कैलिफोर्निया उपभोक्ता गोपनीयता अधिनियम (सीसीपीए)',
  ccpaText: ' यह फॉर्म जमा कर, मैं अपनी व्यक्तिगत जानकारी की बिक्री से बाहर निकलने के अधिकार का प्रयोग करता हूं।',
  submit: 'जमा करें',
  joinKarmaAndGetRewards: 'Karma से जुड़ें और {{name}} पर {{stake, number}} इनाम पाएं।',
  doNotMissOut: 'चूकें नहीं। आप अपने आइटम पर बचत में सक्षम हो सकते हैं!',
  signUpAndGetMoney: 'साइन अप करें, ${{value, number}} पाएं',
  itemAlert: 'आपको आइटम अलर्ट आया है!',
  accessKarmaCash: 'अपने Karma Cash तक पहुंच प्राप्त करें',
  youEarnedKarmaCash: 'आपने अभी-अभी Karma Cash अर्जित किया है!',
  verifyYourPaypalAccount: 'पेपाल<1/>खाता सत्यापित करें',
  weHaveSentAnEmailTo: 'हमने एक ईमेल भेजा है',
  installed: 'इंस्टॉल',
  'Fall Favorites': 'फॉल पसंदीदा',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'क्लोदिंग और लग्जरी',
  'Home Decor & Furniture': 'गृह सज्जा और फर्नीचर',
  'Electronics & Office Supplies': 'इलेक्ट्रॉनिक्स और कार्यालय सामान',
  Luxury: 'लक्ज़री',
  Clothing: 'परिधान ',
  'Handbags & Shoes': 'हैंडबैग और जूते',
  'Jewelry & Watches': 'आभूषण और घड़ियां',
  Accessories: 'एक्सेसरीज',
  'Beauty Essentials': 'सौंदर्य अनिवार्य',
  'Health & Wellness': 'स्वास्थ्य और सेहत',
  Lifestyle: 'जीवन शैली',
  'Sports & Outdoors': 'खेल और आउटडोर',
  Travel: 'यात्रा',
  'Electronics & Office supplies': 'इलेक्ट्रॉनिक्स और कार्यालय सामान',
  'Entertainment & Online Services': 'मनोरंजन और ऑनलाइन सेवाएं',
  Education: 'शिक्षा',
  'Finance & FinTech': 'वित्त और FinTech',
  'Pet Supplies': 'पालतू आपूर्ति',
  'Food & Beverages': 'खाद्य और पेय पदार्थ',
  Men: 'पुरुष',
  Women: 'महिला',
  Unisex: 'महिला-पुरुष',
  Kids: 'बच्चे',
  Baby: 'शिशु',
  notifications: 'सूचनाएं',
  changesSaved: 'बदलाव सहेजा!',
  headerRetailer: 'Karma | रिटेलर',
  headerRedirect: 'Karma | पुन: निर्देशित...',
  karma: 'Karma',
  goBackToKarma: 'Karma पर वापस जाएं',
  authentication: 'प्रमाणीकरण',
  authSuccess: 'बधाईयां! आपने अभी सफलतापूर्वक लॉग इन किया।',
  authFailure: 'ओह, आपको अधिकृत नहीं कर सके। बाद में प्रयास करें।',
  mayNotQualify: 'हो सकता है कुछ उत्पाद Karma Cash के योग्य न हों',
  checkOutRetailers: 'हमारे समर्थित Karma Cash रिटेलर्स देखें।',
  headerHowItWorks: 'Karma | कैसे काम करता है',
  accept: 'सहमति',
  headerError: 'Karma | त्रुटि',
  pageNotFound: 'पृष्ठ नहीं मिला',
  pageDoesNotExist: 'ओह, लगता है यह पृष्ठ मौजूद नहीं है।',
  backHome: 'वापस होम',
  startShopping: 'खरीदारी शुरू करें',
  illustration: 'चित्रण',
  dashboard: 'डैशबोर्ड',
  allRightsReserved: 'सभी अधिकार आरक्षित।',
  product: 'उत्पाद',
  disclaimer:
    'Shoptagr लिमिटेड, Karma के रूप में व्यापार। Karma के पास न तो तीसरे पक्ष का ट्रेडमार्क स्वामित्व है, न ही ऐसा प्रयोजन। तृतीय पक्ष ट्रेडमार्क जो प्रत्यक्ष-अप्रत्यक्ष रूप से Karma के मंच से दिखाई देते, सुलभ होते हैं, जरूरी नहीं उनका Karma या Karma से जुड़े तीसरे पक्षों या Karma के सेवाप्रदाताओं से संबंध हो।',
  help: 'मदद',
  team: 'टीम',
  howItWorks: 'यह कैसे काम करता है',
  supportedStores: 'समर्थित स्टोर',
  company: 'कंपनी',
  planYourPurchase: 'हमारे मुफ्त ऐप से अगली खरीद योजना बनाएं!',
  itSitsInBrowser: 'जब आप पसंदीदा स्टोर ब्राउज करते हैं, यह आपके वेब ब्राउजर में रहता है।',
  seeSmthYouLike:
    'कुछ पसंद का दिखा? इसे सूची में सहेजने हेतु बटन पर क्लिक करें और Karma आपके लिए इसकी कीमत और उपलब्धता पर नजर रखेगा।',
  youWillGetNotified:
    'आपको सहेजे किसी भी आइटम पर सेल होने, कीमत कम होने या पसंद के साइज और रंग के स्टॉक में वापस आने के लिए सूचित किया जाएगा।',
  everythingYouWant:
    'आप जो खरीदना चाहते हैं वह एक सुविधाजनक जगह रहेगा, ताकि आप व्यवस्थित रहें और कई इच्छासूचियों में जाने से बचें।',
  headerContactUs: 'Karma | संपर्क करें',
  needToGetInTouch: 'संपर्क करने की आवश्यकता है?',
  dropALine: 'हमारे लिए संदेश छोड़ें',
  comeHangOut: 'आइए समय बिताएं',
  joinOurFacebookCommunity: 'हमारे फेसबुक समुदाय से जुड़ें',
  followOnInstagram: 'इंस्टाग्राम पर फॉलो करें',
  headerAboutUs: 'Karma | हमारे बारे में',
  addTheItems:
    'आपको जो खरीदारी करनी है वह आइटम जोड़ें - आप सही आकार, रंग हेतु अनुकूलित कर सकते हैं - और जब वह छूट पर होंगी तो ऐप आपको बताएगा।',
  jonathanSay:
    'जॉनथन: जबकि रिटेलर्स वर्षों से ए.आई. का लाभ उठा रहे हैं, अब उपभोक्ता दक्षता के लिए मशीन लर्निंग और ऑटोमेशन टूल का लाभ लेगें।',
  justDownload:
    'बस एक \'बटन\' डाउनलोड कर उसे उस आइटम पर क्लिक करें जिसे आप अपनी सूची में सहेजना चाहते हैं। मोबाइल पर, आप सीधे उसी सूची में आइटम" साझा " कर सकते हैं।',
  ifYouFind:
    'यदि आपको ऑनलाइन कोई आइटम पसंद है जो आपके बजट से बाहर है, तो आप सेल अलर्ट लगा सकते हैं ताकि कीमत कम होने पर आपको सूचना मिले।',
  onlyAHandful: 'Karma समेत, कुछ ही कंपनियां हैं जो ऑनलाइन सदस्यों को सेल अलर्ट सुविधा प्रदान करती हैं।',
  alwaysBuy: 'Karma के साथ हमेशा छूट पर कुछ खरीदें।',
  headerCareers: 'Karma | करियर',
  mentioningKarma: 'Karma का उल्लेख?',
  getInTouch: 'हमसे संपर्क करें:',
  shop: 'दुकान',
  weCouldNotFindResults: "हमें '{{name}} ' हेतु कोई परिणाम नहीं मिला",
  couldNotFindResults: 'हमें कोई परिणाम नहीं मिला',
  shopAllStores: 'सभी समर्थित दुकानों से खरीदें',
  popularStores: 'लोकप्रिय स्टोर',
  shops: 'दुकानें',
  headerDiscoverAndShop: 'Karma | नवीनतम सहेजा आइटम खोजें और खरीदें',
  prev: 'पिछला',
  next: 'आगे बढ़ें',
  headerDemo: 'Karma | डेमो',
  getTheKarmaButton: 'Karma बटन पाएं',
  installedKarma: 'आपने Karma सफलतापूर्वक इंस्टॉल किया!',
  settingUpAccount: 'अब, अपना खाता सेट करके शुरू करें।',
  signUpNow: 'अभी साइन अप करें',
  logInNow: 'अभी लॉग इन करें',
  signUpLater: 'बाद में साइन अप करें',
  wePartnerWith: 'हमारी{{number, number}}K+ से ज्यादा ऑनलाइन दुकानों में साझेदारी!',
  pickStoresToSee: 'नीचे एक दुकान चुनकर देखें यह कैसे काम करता है:',
  leaveKarma: 'ध्यान दें आप Karma को छोड़ एक पूर्ण अनुभव प्राप्त करने के लिए स्टोर पेज पर जाएंगे।',
  searchFavoriteStores: 'अपने पसंदीदा स्टोर खोजें',
  clickBelowToStart: 'शुरूआत हेतु क्लिक करें',
  chooseOneOfTheOptions: 'नीचे दिए विकल्पों में से चुनें',
  clickToBegin: 'प्रारंभ हेतु बार पर क्लिक करें',
  preferNotToSay: 'जवाब नहीं देना चाहता/ती',
  done: 'हो गया',
  headerUnsubscribe: 'Karma | अन-सब्सक्राइब',
  changeMind: 'मन बदल गया?',
  resubscribeList: 'मेलिंग सूची रीसब्सक्राइब करें।',
  resubscribeList_plural: 'यहां अपनी मेलिंग सूची रीसब्सक्राइब करें।',
  changePreferencesAtAnyTime: 'आप अपनी<1>खाता सेटिंग्स</1> के जरिए कभी भी अपनी प्राथमिकताएं बदल सकते हैं।',
  preferencesUpdated: 'ईमेल प्राथमिकताएं अपडेट हो गई हैं।',
  sorryToSeeYouGo: 'आपके छोड़ने का हमें खेद है!',
  reSubscribed: 'आप री-सब्सक्राइब हुए',
  allowHours: 'परिवर्तन प्रभावी करने के लिए कृपया {{hours, number}} घंटे तक का समय दें।',
  missWhenYouGone:
    'जाने पर आप हमें याद आएंगे! परिवर्तन प्रभावी करने के लिए कृपया {{hours, number}} घंटे तक का समय दें।',
  headerDisablingSubscribe: 'Karma | सदस्यता अक्षम',
  noLongerAlerts: 'अब आपको इस आइटम हेतु अलर्ट नहीं मिलेंगे।',
  turnAlertsBack: 'अलर्ट वापस चालू करें।',
  moveAnimations: 'बटन एनीमेशन हिलाएं',
  headerExtensionDeleted: 'Karma | एक्टेंशन हटाया',
  areSorryToSeeYouGo: 'हमें आपके जाने का खेद है!',
  youHaveGotInYourWallet: 'आपके वॉलेट में {{value}} हैं।',
  toWithdraw: ' अपनी कमाई वापसी हेतु!',
  toContinue: ' पुरस्कार अर्जन जारी हेतु!',
  reasonUninstalled: 'कृपया बताएं कि आपने अनइंस्टॉल क्यों किया:',
  getInTouchBtn: 'संपर्क में रहें',
  howWeCanIncrease: 'यहां जाने कैसे आप Karma संग विज्ञापन कर जागरुकता और बिक्री बढ़ा सकते हैं',
  helpingShoppers:
    'निजी खरीदारी सहायक तौर पर हमारी प्राथमिकता खरीदारों को ऐसे ब्रांड्स ढूंढने में मदद की है जिसे वो सालों साल पसंद करें।',
  whyWeOfferBrands:
    'इसलिए हम वह ब्रांड प्रदान करते हैं जो लगातार दर्शकों से जुड़ने हेतु उच्च ग्राहक संतुष्टि प्रदान करते हैं। आपके अभियान लक्ष्य पूरे होंगे और हमारे दर्शक अपने नए पसंदीदा ब्रांड्स से मिलने का मौका पाएंगे।',
  winWin:
    'हम ऐसे ब्रांड्स प्रदान करते हैं जो दर्शकों से जुड़ने हेतु लगातार उच्च ग्राहक संतुष्टि प्रदान करें। आपके अभियान लक्ष्य पूरे होंगे और हमारे दर्शक अपने नए पसंदीदा ब्रांड्स से मिलने का मौका पाएंगे-सभी के लिए अच्छा!',
  getFeaturedRight: 'हमारे ऐप और ब्राउजर एक्सटेंशन डैशबोर्ड के अंदर चित्रित करें',
  getFeaturedDashboard: ' Karma डैशबोर्ड पर प्रदर्शित हों',
  putYourHand:
    'आप अपने उत्पाद, प्रचार, या काम उस जगह पर करें जहां उपयोगकर्ता सीधे खरीदारी करने आता है। हम कस्टम ब्रांडेड सामग्री बना सकते हैं जिससे आप जहां चाहते हैं उपभोक्ता वहां पहुंचेंगे।',
  exactPlace: 'अपने ब्रांड को उस सटीक स्थान पर रखें जहां उपयोगकर्ता खरीदारी हेतु आते हैं।',
  dedicatedInAppPlacement: 'समर्पित इन-ऐप प्लेसमेंट',
  dashboardFeaturedBanners: 'डैशबोर्ड प्रदर्शित बैनर',
  dashboardPopUpMessages: 'डैशबोर्ड पॉप-अप संदेश',
  customEmailsAndNewsLetters: '{{subscribers, number}}+ सब्सक्राइबर्स को कस्टम ईमेल और न्यूजलेटर प्लेसमेंट',
  customEmails: '{{subscribers, number}}+ सब्सक्राइबर्स को कस्टम ईमेल',
  getMoreEyes:
    'खरीदारों को समर्पित ईमेल, न्यूजलेटर प्लेसमेंट के जरिए अपने नवीनतम ऑफर, कूपन कोड, या उत्पाद श्रृंखला पर ज्यादा ध्यान केंद्रित करवाएं।',
  putYourBrand: 'अपना ब्रांड सटीक स्थान पर रखें जहां हमारे उपयोगकर्ता खरीदारी के लक्ष्य से आते हैं।',
  newsletterInclusion: 'न्यूजलेटर समावेश',
  dedicatedCustomEmails: '{{percent, number}}% समर्पित कस्टम ईमेल',
  bannerPlacementInEmails: 'अधिसूचना ईमेल में बैनर प्लेसमेंट',
  pushNotificationsToShoppers: '{{shoppers, number}}+ शॉपर्स को पुश सूचनाएं',
  pushNotificationsGiveTouchPoint:
    'पुश सूचनाओं से आपके अभियान को अतिरिक्त स्पर्श बिंदु और ज्यादा ओपन, क्लिक रेट मिलता है।',
  featureBrandClients: 'फीचर्ड ब्रांड ग्राहक',
  growingPerMonth: 'प्रति माह +{{number, number}}k बढ़ोतरी',
  members: 'सदस्य',
  percent: '{{value, number}}%',
  kindOfReachYouExpect: 'आप कैसी पहुंच की उम्मीद कर सकते हैं?',
  ourAudience: 'हमारे दर्शक',
  tabIntoNewSource: 'अपने अगले अभियान हेतु योग्य ग्राहकों के नए स्रोत पर टैप करें',
  tellUsAboutYourCompany:
    'हमें अपनी कंपनी और लक्ष्यों के बारे में थोड़ा और बताएं और हम आपको दिखाएंगे कि उन्हें कैसा पार करें।',
  openAndTap: 'सफारी खोलें और जिसे सहेजना चाहते हैं उस उत्पाद पृष्ठ पर जाएं फिर \'शेयर" बटन टैप करें।',
  shareIllustration: 'चित्रण साझा करें',
  tapMore: "पैनल के दाईं ओर' अधिक ' बटन टैप करें।",
  moreIllustration: 'अधिक चित्रण',
  enableIllustration: 'चित्रण सक्षम करें',
  tapEdit: "पैनल के शीर्ष पर' संपादित करें ' बटन टैप करें।",
  enableApp: "Karma सक्षम करें और इसे अपने पसंदीदा में जोड़ने हेतु हरे 'जोड़ें' बटन पर टैप कर फिर 'पूर्ण' टैप करें।",
  goBackToTheProduct:
    "उत्पाद पृष्ठ पर वापस जाएं, फिर 'शेयर' बटन टैप करें, फिर अपने उत्पाद को सहेजने हेतु 'Karma' बटन टैप करें।",
  recommendedBy: 'द्वारा अनुशंसित',
  ourMembersSavingMoney: 'हमारे सदस्य Karma द्वारा बचत❤️ करते हैं। ऐसा इसलिए।',
  ourMembersSavingMoney2: 'हमारे सदस्य Karma द्वारा बचत <1/> करते हैं। ऐसा इसलिए।',
  addToBrowser: '{{browser}} में जोड़ें - मुफ्त है',
  planPurchases: 'अगली खरीदारी योजना बनाएं',
  karmaMakesItEasy: 'Karma आपकी अगली खरीदारी एक जगह पर व्यवस्थित करना आसान बनाता है।',
  buyAtRightTime: 'सही समय पर खरीदें',
  automaticallyAlert: 'खरीदारी के सही समय के लिए Karma स्वत: आपको सचेत करेगा।',
  cashOnVacation: 'आपकी अगली छुट्टियों पर Karma Cash',
  karmaPartners: 'Karma कई ट्रैवल, होटल साइटों के साथ भागीदार।',
  headerRewards: 'Karma | सर्वश्रेष्ठ कूपन, नकद इनाम',
  mission: 'हमारा मिशन',
  empowerYou: 'ऑनलाइन शॉपिंग के दौरान आपको सही समय पर सही चुनाव करने के लिए सशक्त बनाना।',
  weAreSeriousAboutPrivacy: 'हम आपकी निजता, सुरक्षा को लेकर गंभीर हैं',
  weDoNotSellData: 'हम आपका डेटा नहीं बेचते',
  karmaIsSerious: 'Karma आपके डेटा को लेकर गंभीर है, हम कभी आपका डेटा किसी तीसरे पक्ष को नहीं बेचेंगे। कदापि।',
  karmaIsSecure: 'Karma सुरक्षित है',
  weUseBankSecurity:
    'हम बैंक-स्तर की सुरक्षा ({{bit, number}}-bit encryption) इस्तेमाल करते हैं ताकि आपकी जानकारी सुरक्षित रहे।',
  helpsSaveMore: 'Karma से ज्यादा बचत में मदद!',
  alertMe: 'अलर्ट करें',
  buyWithKarmaEarn: 'Karma साथ खरीदें - ${{number, number}}कमाएं',
  productSaved: 'बधाईयां! यह उत्पाद आपकी आइटम सूची में सहेजा गया है। कीमत गिरने पर आपको सूचित किया जाएगा।',
  willApplyTheBestCoupons: 'Karma स्वत: आपकी खरीद के लिए सर्वोत्तम कूपन लागू करेगा!',
  downloadAndAutoApply: 'डाउनलोड Karma और स्वत: लागू',
  whatIsKarma: 'Karma क्या है?',
  claimUpToRewards: '{{{number, number}}% नकद पुरस्कार तक दावा करें',
  youMightAlsoLike: 'अच्छा लग सकता है',
  weAreSorry: 'खेद है, कुछ गड़बड़ी के कारण पृष्ठ प्रदर्शित नहीं किया जा सकता।',
  tryToReloadPage: 'कृपया, पृष्ठ दोबारा लोड करें।',
  Color: 'रंग',
  Size: 'आकार',
  latestCoupons: '{{name}} | Karma के नवीनतम कूपन और कैशबैक {{year, clearNumber}}',
  neverPayFullPrice: 'कभी पूरा न चुकाएं। Karma के {{name}} कूपन, कैशबैक पाएं',
  neverPayFullPriceForTag: '{{name}}हेतु कभी पूरा न चुकाएं',
  saveMoneyWhenShoppingAt: '{{name}} पर खरीदारी पर पैसे बचाएं। Karma से मुफ्त जुड़ें',
  saveMoneyWhenShoppingFor: '{{name}} की खरीदारी पर पैसे बचाएं। Karma से मुफ्त जुड़ें',
  tagByBrand: 'Karma | {{tag}} by {{brand}}',
  typeToSearch: 'खोजने हेतु टाइप',
  getUSDWhenJoinToday: 'आज Karma से जुड़ने पर ${{value, number}} पाएं!',
  buyProduct: 'उत्पाद खरीदें',
  userWishList: 'Karma | {{name}} इच्छा सूची',
  byWhom: 'द्वारा',
  copiedToClipboard: 'क्लिपबोर्ड में कॉपी हो गया!',
  send: 'भेजें',
  cause: 'कारण चुनें:',
  aCause: 'कोई कारण चुनें:',
  close: 'बंद',
  selectThisCause: 'यह कारण चुनें',
  habitatForHumanity:
    '{{name}} वहनीय सोशल हाउसिंग द्वारा दीर्घकालिक गरीबी उन्मूलन करता है, इनका उद्देश्य सुरक्षित परिवेश का निर्माण करना है जहाँ परिवार और समुदाय जीवन जी सकें',
  casaDescription:
    'नेशनल CASA/GAL असोसिएशन, न्यायालय-नियुक्त स्वयंसेवी अधिवक्तृता को समर्थन और बढ़ावा देता है ताकि दुर्व्यवहार और अवहेलना झेल चुके हर बच्चे को सुरक्षा, स्थायी घर, और जीने का अवसर दिया जा सके',
  endHomelessnessDescription:
    'नेशनल अलायंस {{name}} एक गैर-पक्षावलम्बी संस्था है जो यूनाइटेड स्टेट्स में आवासहीनता रोकने और समाप्त करने हेतु प्रतिबद्ध है',
  pathDescription: 'PATH व्यक्तियों, परिवारों, समुदायों की आवासहीनता समाप्त करती है',
  feedingAmericaDescription: 'Feeding America देश की सबसे बड़ी स्वदेशी क्षुधा-मुक्ति संस्था है',
  mealsOnWheelsDescription:
    '{{name}} स्थानीय सामुदायिक कार्यक्रमों को सहयोग देता है ताकि वे वरिष्ठ नागरिकों की सेवा  करके उनके स्वास्थ्य और जीवन-स्तर बेहतर बनाकर सबकी भूख व अकेलापन दूर करें',
  actionAgainstHunger:
    '{{name}} एक वैश्विक लोकोपकारी संस्था है जो भुखमरी के कारणों और प्रभावों हेतु निर्णायक कदम उठाती है',
  supportMedicalAdvancement: 'चिकित्सा उन्नति सहयोग',
  saveChildsHeartDescription:
    "Save A Child's Heart नस्ल, धर्म, लिंग, राष्ट्रीयता, वित्तीय-स्तर देखे बिना हृदय ठीक करता है",
  su2c: 'Stand Up To Cancer (SU2C) आज रोगियों हेतु नए और सबसे आशाजनक कैंसर उपचार फंड व विकसित करता है',
  drWithoutBorders: '{{name}} स्वतंत्र, वैश्विक आंदोलन जो ज़रूरतमंद स्थानों पर चिकित्सीय सेवा दे रहा है',
  activeMinds: '{{name}} देश की प्रमुख गैर-लाभ संस्था जो वयस्कों हेतु मानसिक स्वास्थ्य जागरूकता और शिक्षा देती है',
  cleanAirTaskForceDesc:
    '{{name}} वहनीय लागत पर शून्य-उत्सर्जन, उच्च-ऊर्जा ग्रह बनाने के लिए आवश्यक तकनीकी और नीतियों संबंधी बदलावों को प्रोत्साहित करता है',
  wwfDesc: '{{name}} का उद्देश्य प्रकृति का संरक्षण और धरती के विविधतापूर्ण जीवन के लिए सबसे गंभीर खतरों को कम करना है',
  waterkeepsDesc:
    '{{name}} स्वच्छ जल हेतु संघर्षरत। Waterkeeper Alliance हर समुदाय हेतु पीने, फिशिंग, तैरने योग्य जल हेतु प्रयासरत है',
  xprice:
    'हर दिन XPrize ऐसी बेहतर दुनिया बनाने हेतु कार्यरत है जिसमे हर किसी को स्वच्छ जल, पोषक भोजन, सस्ता घर, प्रभावी शिक्षा, उच्च-स्तरीय चिकित्सा और गैर-प्रदूषक, प्रचूर ऊर्जा प्राप्त हो',
  globalActDesc: '{{name}} यौन और वाणिज्यिक शोषण समाप्त करने हेतु कम करती है',
  pcaa: '{{name}} हमारे ऐसा सपना पूरा करने हेतु परिश्रमरत है जिसमे सहयोगी परिवारों, समुदायों में सभी बच्चे खुश, स्वस्थ बड़े हों और सफलता पाने हेतु तैयार हों',
  endCAN:
    'EndCAN बच्चों से दुर्व्यवहार और अवहेलना की समाप्ति के उद्देश्य सहित दुर्व्यवहार के मानसिक स्वास्थ्य और जन स्वास्थ्य प्रभावों संबंधी जागरूकता बढ़ाता है',
  futuresDesc:
    'FUTURES दुनियाभर में महिलाओं और बच्चों से होने वाली हिंसा समाप्त करने हेतु कार्यरत व्यक्तियों, संस्थाओं हेतु अभूतपूर्व कार्यक्रम, नीतियाँ, अभियान चलाता है',
  thornDesc: '{{name} बाल यौन दुर्व्यवहार से रक्षण हेतु तकनीक निर्माण।',
  'Eradicate Homelessness': 'आवासहीनता दूर करें',
  'Help End Hunger': 'भुखमरी समाप्ति सहायता ',
  'Support Medical Advancement': 'चिकित्सा उन्नति सहयोग',
  'Combat Climate Change': 'पर्यावरण परिवर्तन युद्ध',
  'Act To End Abuse': 'दुर्व्यवहार समाप्त करें',
  itemsYouSaved: 'आपने कम में वस्तुएं सहेजीं',
  beta: 'BETA',
  alsoAvailableAt: 'पर भी उपलब्ध',
  atName: '{{name}} पर',
  bestPrice: 'बेहतरीन मूल्य',
  passwordWasSuccessfullyReset: 'पासवर्ड सफलतापूर्वक रिसेट किया',
  paidWith: 'सहित भुगतान',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'सदस्य',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'मासिक विज़िट',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'ईमेल सूची',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'मिलेनियल',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'महिलाएं',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'यूएस स्थित',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'ऑप्ट-इन मोबाइल पुश नोटीफिकेशन',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: '{{year, clearNumber}} के अंत तक प्रयोक्ताओं द्वारा खर्च की जाएगी',
  karmaIsAlwaysWithYou: 'Karma खरीदते समय हमेशा आपके साथ है',
  saveItemsToBuy: 'वस्तुएं सहेजें और हम कब खरीदना है आपको बताएंगे',
  weDoTheWork: 'हम आपके लिए बेहतरीन कूपन खोजने का काम करते हैं',
  earnRealCashThatAdds: 'असली नकद पाएं जो आपके वॉलेट में जुड़े',
  setUpStrongPassword: 'मज़बूत पास्वोर्ड बनाएं',
  settingEmailAccount:
    'Karma के साथ पैसे सहेजने हेतु आखरी कदम? साइन अप करें ताकि हम आपकी वस्तुओं, जैसे मूल्य में कमी, संबंधी ख़ास सूचनाएं बता सकें।',
  settingPasswordAccount:
    'पासवर्ड में कम से कम {{number, number}} केरैक्टर होने चाहिएं और इसमें आपके ईमेल के क्रेडेंशियल नहीं होने चाहिएं।',
  countWaysToUse: 'Karma का उपयोग करने के {{number, number}} तरीके',
  goBack: 'वापस जाएं',
  pleaseChooseStrongerPassword: 'कृपया एक मजबूत पासवर्ड चुनें',
  pleaseEnterValidEmailAddress: 'कृपया एक मान्य ईमेल पता दर्ज करें',
  illSignUpLater: 'मैं बाद में साइन अप करूंगा',
  enterYourEmailAddress: 'कृपया अपना ईमेल पता दर्ज करें जहां हम आपको अपना पासवर्ड रीसेट करने के लिए एक लिंक भेजेंगे।',
  headerOnboardingIosExtension: 'Karma | ऑनबोर्डिंग आईओएस एक्सटेंशन',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'आप जहां भी खरीदारी करेंगे, हम स्वचालित रूप से कूपन लागू कर देंगे.',
  continue: 'जारी रखें',
  changeLanguage: 'भाषा बदलें',
  selectedLanguage: 'चयनित भाषा',
  headerMuteItem: 'Karma | म्यूट आइटम',
  itemHasBeenMuted: 'पूर्ण! यह आइटम म्यूट कर दिया।',
  toUnmuteItem: 'आइटम अनम्यूट करने हेतु:',
  headToMyItems: "'माई आइटम्स' टैब पर जाएं",
  clickThreeButtonsInItem: 'आइटम के निचले दाएं कोने पर तीन बटन क्लिक करें',
  clickUnmute: "'अनम्यूट' क्लिक-करें",
  weveJustRaisedInFunding: 'हमने सीरीज़ A फंडिंग में अभी-अभी ${{value, number}}M जुटाए ताकि आप सही खरीदारी करें!',
  weveJustRaised: 'हमने में अभी-अभी ${{value, number}}M',
  landingReview1: 'बढ़िया ऐप! मुझे मूल्य ड्रॉप अलर्ट मिला और बहुत सारा पैसा बचाया!',
  landingReview2: 'मेरी पहली खरीदारी पर मैंने ${{value, number}} बचाए। बहुत प्रभावित!',
  landingReview3: 'यह लाभकारी ऐप है; सूचनाएं और पुरस्कार पसंद आए।',
  landingReview4: 'मैंने अभी तक इससे अधिक सहज खरीदारी नहीं देखी!',
  landingReview5: 'मैंने {{value, number}}% बचाए! मुझे अन्यथा सेल के बारे पता नहीं चलता!',
  landingReview6: 'अपने पसंदीदा उत्पादों को ट्रैक करना आसान... अत्यधिक अनुशंसित...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'मेरा पासवर्ड रीसेट करें',
  repeatPassword: 'पासवर्ड दोहराएं',
  continueAsGuest: 'अतिथि के रूप में जारी रखें',
  viewAt: '{{- name}} पर देखें',
  signUp: 'साइन अप करें',
  youMayAlsoLike: 'आप यह भी पसंद कर सकते हैं',
  buyOnShop: 'पर खरीदें',
  viewOn: '{{- name}} पर देखें',
  quickView: 'जल्दी देखें',
  newList: 'नई सूची',
  undo: 'पूर्ववत',
  productDeleted: 'उत्पाद हटाया गया',
  linkCopiedToClipboard: 'लिंक क्लिपबोर्ड पर कॉपी किया गया',
  wishlists: 'इच्छासूची',
  headerPartnerships: 'Karma | साझेदारी',
  letsJoinForces: 'आइये साथ मिलकर बढ़ें',
  bePartOfTheSmartShoppingRevolution:
    'स्मार्ट शॉपिंग क्रांति का एक हिस्सा बनें। हम आपके ब्रांड को {{number, number}} मिलियन से ज़्यादा खरीददारों के सामने लाएंगे जो चेक आउट के लिए तैयार हैं।',
  seeKarmaInAction: 'Karma को क्रिया में देखें',
  discoverHowKarma:
    'खोजें कैसे कर्मा के AI और स्वचालन-आधारित समाधान पूरी शॉपिंग जर्नी को बढ़ाते हैं, आपके ऑफर तक लक्षित लोगों को ले जाते हैं।',
  stepUpYourSales: 'अपनी बिक्री बढ़ाएं',
  averageROAS: 'औसत<br/>ROAS',
  increaseInAOV: 'AOV<br/>में वृद्धि',
  useKarmaToPlanTheirNextPurchase: 'कर्मा का उपयोग उनकी अगली<br/>खरीद की योजना बनाने के लिए करें',
  meetOurShoppers: 'हमारे खरीददारों से मिलें',
  meetOurShoppersDescription:
    'हमारे प्रदर्शन-संचालित मार्केटिंग समाधानों का लाभ उठाएं और खरीददारी के उच्च इरादे का प्रभाव खरीददारों पर डालें। हमारे क्रॉस-प्लेटफ़ॉर्म प्लेसमेंट आपको उपयोगकर्ताओं तक पहुंचने में सक्षम बनाते हैं, चाहे वे कहीं भी हों।',
  audienceInfoFirstNumber: '{{number, number}}{{unit}}+',
  audienceInfoFirstTitle: 'उपयोगकर्ता',
  audienceInfoSecondNumber: '{{number, number}}{{unit}}+',
  audienceInfoSecondTitle: 'मासिक दौरे',
  audienceInfoThirdNumber: '{{number, number}}{{unit}}+',
  audienceInfoThirdTitle: 'सहेजी गईं वस्तुएं',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'यूएस के उपयोगकर्ता',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'जेनरेशन Z और मिलेनियल्स',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'महिला खरीददार',
  ourUsersLoveUs: 'हमारे उपयोगकर्ता हमसे प्यार करते हैं!',
  partnershipsReview1:
    'एक बढ़िया विस्तार! मैंने सालों से इसका उपयोग किया है और एक ही स्थान पर बहुत सारे उत्पादों को सहेजने में सक्षम होना बहुत अच्छा है - यह ऑनलाइन खरीदारी को कम तनावपूर्ण बनाता है!',
  partnershipsReviewAuthor1: 'मिली रॉबिन्सन',
  partnershipsReview2:
    'एक ऐसे व्यक्ति से मिलकर जो बहुत ज़्यादा ऑनलाइन शॉपिंग करता है, मैं ईमानदारी से कह सकता हूं कि कर्मा कई कारणों से खरीददारों का सबसे अच्छा दोस्त है...',
  partnershipsReviewAuthor2: 'सेडी मे',
  partnershipsReview3:
    'प्ले स्टोर में सबसे बढ़िया शॉपिंग ऐप। उपयोग करने में बहुत आसान। मेरे पास सभी सहेजी गईं वस्तुएं एक ऐप में मेरे पसंदीदा स्टोर में हैं!',
  partnershipsReviewAuthor3: 'किम्बर्ली रोजर्स',
  performanceBasis: 'प्रदर्शन का आधार',
  fixedFee: 'निश्चित शुल्क',
  thanksForSubmitting: 'सबमिट करने के लिए धन्यवाद!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly: 'हमारी साझेदार टीम में से कोई शीघ्र ही संपर्क करेगा।',
  letsKickStartOurPartnership: 'आइए अपनी साझेदारी शुरू करें!',
  emailOrPhoneNumber: 'ईमेल या फोन नंबर',
  retailerName: 'रिटेलर का नाम',
  affiliateNetwork: 'सहसंबद्ध नेटवर्क',
  budgetStructure: 'बजट संरचना',
  budget: 'बजट',
  tellUsMore: 'हमें और बताएं',
  pleaseFillInAllRequiredFields: 'कृपया सभी आवश्यक फील्ड भरें',
  partnerships: 'साझेदारी',
  weCannotSaveThisItem: 'हम इस आइटम को सेव नहीं कर सकते <1/> क्योंकि यह स्टोर में नहीं होता',
  theresCoupon: 'एक कूपन मिला!',
  yourItemIsOffWithCode: 'आपके आइटम {{- name}} पर कोड {{code}} सहित {{value, number}}% की छूट है।',
  muteAllNotification: 'सभी अधिसूचना म्यूट करें',
  unMuteAllNotification: 'सभी अधिसूचना अनम्यूट करें',
  notificationSettings: 'अधिसूचना सेटिंग्स',
  deleteNotification: 'अधिसूचना हटाएं',
  notificationDeleted: 'अधिसूचना हटाई गई',
  itemMuted: 'आइटम म्यूट किया गया',
  itemUnMuted: 'आइटम अनम्यूट किया गया',
  priceDrop: 'कीमतों में गिरावट',
  superDeal: '🔥 सुपर डील',
  notSureWhenToBuy: 'सुनिश्चित नहीं है कि कब खरीदें? एक निशुल्क खाता बनाएं और डील का समय हम आपको बताएंगे।',
  tcMayApply: 'नियम और शर्तें लागू हो सकती हैं',
  earnKarmaCash: '{{percent, number}}% तक कर्मा कैश कमायें।',
  saveToKarma: 'कर्मा में उत्पाद सहेजें',
  getKarmaToTrackItem: 'उत्पाद पर नज़र रखने के लिए कर्मा लें',
  itemSaved: 'उत्पाद सहेजा गया!',
  youCanNowFindItem: 'अब आप अपने उत्पाद को कर्मा डैशबोर्ड पर देख सकते हैं।',
  getKarmaExtension: 'कर्मा एक्सटेंशन ले कर <1/> इस आवश्यक उत्पाद पर नज़र रखें',
  notifyYou: 'जब खरीदने का बेहतरीन समय होगा, तब हम आपको सूचित करेंगे!',
  downloadTheExtension: 'एक्सटेंशन डाउनलोड करें',
  outOfStock: 'स्टॉक में नहीं है',
  invalidEmailError: 'गलत ईमेल',
  collapse: 'संक्षिप्त करें',
  expand: 'विस्तृत करें',
  lists: 'सूचियाँ',
  organizeYourItemsIntoWishListsWithJustAClick: 'केवल एक क्लिक से अपने उत्पादों को विश लिस्ट में व्यवस्थित करें',
  newItem: 'नया उत्पाद',
  helpCenter: 'सहायता केंद्र',
  priceHighToLow: 'अधिक से कम मूल्य',
  priceLowToHigh: 'कम से अधिक मूल्य',
  sale: 'सेल',
  filterBy: 'फ़िल्टर करें:',
  karmaExclusive: 'कर्मा से एक्सक्लूसिव',
  noItemsFound: 'कोई उत्पाद नहीं मिला',
  select: 'चुनें',
  likeSneakersOrBlackFriday: "जैसे 'स्नीकर्स' या 'ब्लैक फ्राइडे'",
  updateList: 'लिस्ट को अपडेट करें',
  firstName: 'प्रथम नाम',
  lastName: 'अंतिम नाम',
  enterFirstName: 'प्रथम नाम डालें',
  enterLastName: 'अंतिम नाम डालें',
  recentSearches: 'हाल की खोज',
  addYourFavoriteStores: 'अपने फेवरेट स्टोर जोड़ें',
  toAccessTheirLatestCoupons: 'उनके नवीनतम कूपन देखने के लिए',
  logosProvidedByClearbit: 'क्लियरबिट द्वारा लोगो दिया गया',
  dateOfBirth: 'जन्म तिथि',
  weHaveEmailedYouALinkToResetYourPassword: 'हमने आपको आपका पासवर्ड रिसेट करने के लिए एक लिंक भेजा है।',
  didNotGetIt: 'नहीं मिला?',
  profile: 'प्रोफ़ाइल',
  promotionsAndActivity: 'प्रमोशन और गतिविधि',
  couponsForFavoriteStores: 'पसंदीदा स्टोर के कूपन',
  accountActivity: 'खाते की गतिविधि',
  powerShopper: 'शक्तिशाली खरीददार? 💪',
  enterLinkToAnyItemPage: 'किसी भी उत्पाद के पेज पर जाने के लिए लिंक में प्रवेश करें',
  saveStuffWithZeroFuss: '<0>ब्राउज़र एक्सटेंशन लें</0> और बिना परेशानी के बचत करें।',
  viewMore: 'अधिक देखें',
  tooHotToMiss: 'इसे छोड़ा नहीं जा सकता',

  // WebsiteLandingKarmaOld
  joinShoppers: '{{number, number}} मिलियन शॉपर्स से जुड़ें',
  waysToUse: 'Karma इस्तेमाल के तरीके',
  youCanUseKarma:
    'Karma का उपयोग कई तरीकों से हो सकता है जैसे अपनी अगली खरीदारी योजना बनाने के साथ सही समय पर बेहतरीन दाम पाना सुनिश्चित करना। हमारे द्वारा अनुशंसित कुछ उदाहरण:',
  livesInBrowser: 'Karma ब्राउजर में रहता है',
  browserExtension: 'वेब में जहां खरीदारी करेंगे Karma ब्राउजर एक्सटेंशन आपको वहां मिलेगा।',
  helpsYouPlan: 'अगली खरीदारी योजना बनाने में सहयोगी',
  notReadyToBuyJust:
    'अभी खरीदने के लिए तैयार नहीं? Karma ब्राउजर एक्सटेंशन में बाद हेतु सहेजें और सचेत होकर खरीदारी करें। एक जगह पर अपने सभी सहेजे हुए आइटमों पर नजर रख सकते हैं। ',
  automaticallyFindsCouponCodes: 'स्वत: कूपन कोड खोजेगा',
  findsAndAppliesCodes:
    'Karma स्वत: सर्वश्रेष्ठ कूपन खोजता, लागू करता है, जिससे आप जानते हैं कि हर बार आपको बेहतरीन मूल्य मिलता है।',
  soYouCanBuyAtTheRightTime: 'ताकि आप सही समय में खरीदें',
  usesCleverTechnologies:
    'Karma तेज तकनीक का इस्तेमाल कर कीमत कम होना, या स्टॉक में वापस आना ट्रैक करता है, ताकि आप सही समय पर खरीद पाएं। ',
  notOnlySave:
    'Karma न सिर्फ आपके पैसे बचाता है- ये पैसे बनाता भी है! कोई अंक नहीं। कोई चालबाजियां नहीं। हर खरीद पर आप नकद अर्जित करते हैं।',
  makeImpact: 'असर डालें!',
  donate:
    'सामान्यत: खरीदारी करें, देखें कि Karma कैसे वापस देता रहता है। ${{over, number}} से ऊपर की खरीद पर, हम ${{dollars, number}} आपके चुनें कारण को दान देते हैं।',
  accessToOffers: 'खास ऑफर तक पहुंच',
  accessItems: 'एक जगह पर सभी सहेजे आइटमों तक पहुंचें, खास ऑफर्स पाएं, Karma पर देखें की क्या चलन में है।',
  hereIsHowItWorks: 'यह ऐसे काम करेगा',
  learnMoreAboutGives: 'Karma Gives के बारे में अधिक जानकारी पाएं',
  alwaysKnowWhenPricesDrop: 'कीमतें गिरने पर जानकारी पाएं',
  getNotifiedWhenBackInStock: 'इसके स्टॉक में आने पर सूचना पाएं',
  findsYouBestCoupons: 'सबसे अच्छे कूपन ढूंढता है',
  getKarmaCashWhenYouShop: 'खरीदारी करते समय Karma नकद पाएं',
  easilySaveAllThings: '{{value, number}}K+ स्टोर्स पर अपनी सभी पसंदीदा चीज़ों पर बचत करें',
  karmaProvidesGreatTechnology:
    'Karma आपको सही खरीदारी हेतु बेहतरीन तकनीक और लचीलापन देता है, जिससे अनावश्यक आवेगों से बचने में मदद मिलती है, जिससे समय और पैसा बचता है।',

  accountActivityAndImportantInformation: 'अकाउंट एक्टिविटी और महत्वपूर्ण सूचना',
  addKarmaToYourDesktop: 'कर्मा को अपने डेस्कटॉप से जोड़ें ताकि आप काम के घंटों <br/>के दौरान खरीदारी कर सकें।',
  addNumberItemsToList: 'सूची में {{value}} आइटम जोड़ें',
  addOneItemToList: 'सूची में 1 आइटम जोड़ें',
  addStuffYouFindToThisListToKeepItOrganized:
    'आपको जो सामग्री मिले उसे इसमें जोड़ें ताकि इस सूची को व्यवस्थित रखा जा सके।',
  alrightBut: 'ठीक है, लेकिन आप कुछ बड़ा मिस करने जा रहे हों!',
  areYouSureYouWantToDisconnectFromPaypal: 'क्या आप वाकई पेपॉल से डिसकनेक्ट करना चाहते हैं?',
  balance: 'शेष',
  buyWhateverYouWant: 'आप जो चाहें खरीदें और बैंक लेडी को खुश रखें।',
  cashIsBack: '<1>कैश</1> वापस आया है',
  cashIsBackColoredRight: 'कैश <1>वापस</1> आया है',
  cashOutRewards: 'पुरस्कार नकद में लें',
  checkItOffAsYouBuy: 'खरीदते समय इसे चेक करें',
  congratulationsOnCashout: 'बधाईयां! अब आप अपने पुरस्कारों को नकद में ले सकते हैं।',
  connectToPayPalToCashOut: 'अपने पुरस्कारों को नकद में लेने के लिए पेपॉल अकाउंट जोड़ें',
  connectYourPaypalAccountToCashOutRewards: 'अपने पुरस्कारों को नकद में लेने के लिए अपना पेपॉल अकाउंट जोड़ें',
  downloadOurApp: 'हमारा ऐप डाउनलोड करें और लें <1>कर्मा टू-गो</1>',
  dropSomeShoppingInspo: 'अपने परिवार पर कुछ खरीदारी निरीक्षण छोड़ें।',
  earnKarmaCashShort: '{{percent, number}}% तक K कैश',
  earned: 'अर्जित',
  enterYourPassword: 'नया पासवर्ड डालें',
  firstAddToList: 'पहले, सूची से जोड़ें',
  followOnLinkedin: 'लिंक्डइन पर हमें फॉलो करें',
  freshOutCoupons: 'कूपन अभी-अभी खत्म हुए हैं',
  getBuzzedWhenYourWishes: "जब आपकी 'विशेज़' की कीमत में गिरावट आएगी या स्टॉक वापस आएगा तो आवाज़ 🐝 दी जाएगी।",
  getIt: 'उसे ले लो',
  getTheApp: 'ऐप प्राप्त करें',
  gotIt: 'समझ गया',
  gotStuck: 'फंस गए? आगे बढ़ने के लिए <1>यहां</1> क्लिक करें।',
  happyShoppers: '👉 {{count, number}}M+ खुश खरीदार',
  howElseCanWeImproveKarma: 'हम कर्मा को और कैसे सुधार सकते हैं?',
  iNeedThisInMyLife: 'अपने जीवन में मुझे इसकी ज़रूरत है',
  info: 'सूचना',
  itemUpdatesUnsubscribe: 'आइटम अपडेट्स (कीमतों में गिरावट, रीस्टॉक्स, और बहुत कुछ)',
  itsAGhostTown: 'यह भूतों का शहर है',
  joinTheTeam: 'टीम में शामिल हों',
  karmaGetsYouTheLowestPossiblePrice: 'कर्मा आपको न्यूनतम संभव कीमत दिलाएगा 👏 हर👏 बार।',
  karmaIsAnApp: 'कर्मा एक ऐसा ऐप और एक्सटेंशन है जो ऑनलाइन खरीदारी करते समय आपको समय और पैसा बचाने में मदद करता है।',
  karmaYourFavoriteBrands: '<1>कर्मा</1> आपके पसंदीदा ब्रांड',
  karmaYourFavoriteBrandsMobile: '<1>कर्मा</1> आपके पसंदीदा ब्रांड',
  knowHowToShopBetter: 'जानें कि अन्य सभी से <1>बेहतर खरीदारी</1> कैसे करें',
  letsPlay: 'आइए, हम खेलें',
  lolWereAVerbNow: '(LOL अब हम एक क्रिया हैं)',
  lookAtYouLittleScroller: 'खुद को देख लो, तुम नन्हा स्क्रॉलर। यहां तक पहुंचने के लिए बधाई।',
  lookAtYouLittleScrollerMobile: 'खुद को देख लो, तुम नन्हा स्क्रॉलर।',
  lookingForInfluencer: 'विज्ञापन भागीदारी खोज रहे हैं?',
  markItemsYouveMadeYourOwnItFulfillsSoGood:
    'उन वस्तुओं को चिह्नित करें जिन्हें आपने खुद बनाया है। यह बहुत अच्छा पूरा करता है!',
  markNumberItemsAsFullfilled: 'आप {{value}} आइटम के पूरा होने का चिह्न लगाने वाले हैं',
  meetYouAtIn: '3 सेकंड में {{retailer}} पर आपसे मिलते हैं...',
  missOut: 'मिस करें',
  missingYouAlready: 'हम आपको मिस कर रहे हैं!',
  money: 'पैसा',
  never: 'कभी नहीं',
  nevermind: 'कोई बात नहीं',
  noFomoHere:
    "यहां कोई बड़ा मिस नहीं होगा. जब आपकी 'विशेज़' की कीमत में गिरावट आएगी या स्टॉक वापस आएगा तो हम आपको एक आवाज़ देंगे।",
  noNotificationsYet: 'आप सब फंसे हुए हैं',
  noNotificationsYet2: 'जैसे ही कीमतों में गिरावट या रीस्टॉक अपडेट होगा, आप इसे यहां पाएंगे।',
  nowLetsTalPrivacy: 'अब हम बात करते हैं <1>प्राइवेसी<1/> की',
  nowLetsTalkAboutPrivacy: 'अब हम बात करते हैं प्राइवेसी की 🤫',
  onlineShoppingButBetter: 'ऑनलाइन शॉपिंग लेकिन <1>बेहतर</1>',
  orCopyTheLinkBelowAndSend: 'या, नीचे दिए गए लिंक को कॉपी करें और उन्हें ओल्ड-स्कूल शैली में भेजें।',
  other: 'अन्य',
  passwordValidationNote: 'कम से कम 8 कैरक्टर ज़रूरी हैं, जिसमें एक अपरकेस अक्षर, एक लोअरकेस अक्षर और एक अंक हों।',
  payLess: 'भुगतान करें कम',
  playFavesWithYourItems: 'अपने आइटम के साथ पसंदीदा प्ले करें',
  preparingYourKarmaCash: 'आपका कर्मा कैश तैयार हो रही है।',
  savingMoneyIsHotLetsGetOnThat: 'पैसा बचाना सुंदर है। आइए उस पर चलते हैं ✌️',
  search: 'ढूंढें',
  seeHowToSave: 'देखें कैसे बचत करेें',
  skipForNow: 'अभी रहने दो',
  soFarWeSavedYou: 'अब तक, हमने आपको सेव किया है',
  sorryForInconvenience: 'असुविधा के लिए खेद है - लेकिन हमारा वादा है कि यह बेहतर होगा.',
  starReviews: '👉 {{count, number}}K+ 4.7 स्टार रिव्यूज़',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'अपने जीवन में आवश्यक चीज़ों को स्टार लगाएं ताकि त्वरित एक्सेस की जा सके।',
  stillWannaUnsubscribe: 'अभी भी <1>कर्मा के सभी ईमेल्स को अनसबस्क्राइब करना चाहते हैं</1>?',
  storePromotionsAndOtherMarketingEmails: 'स्टोर प्रमोशन्स और अन्य मार्केटिंग ईमेल्स',
  storesWorldwide: '👉 {{count, number}}K+ स्टोर्स दुनिया भर में',
  stuffWorthSavingGoesHere: 'बचत के लायक सामान यहां जाता है 👇',
  sureYouWantToDeleteTheseNumberItems: 'क्या आप वाकई इन {{value}} आइटम को हटाना चाहते हैं?',
  sureYouWantToDeleteThisList: 'क्या आप वाकई इस सूची को हटाना चाहते हैं?',
  takeControlOfYourInbox: 'अपने इनबॉक्स को नियंत्रण में लें',
  theMotherOfAllWishLists: 'सभी <1>इच्छा सूचियों<1/> की मां',
  thisFieldIsRequired: 'यह फ़ील्ड ज़रूरी है',
  toCashOutRewards: 'अपने पुरस्कारों को नकद में लेने के लिए, आपको कम से कम ${{minimum, number}} तक पहुंचना होगा।',
  toCashOutYouMustReach:
    'अपने पुरस्कारों को नकद में लेने के लिए, आपको कम से कम ${{currency}}{{value, number}} तक पहुंचना होगा।',
  tryAgain: 'दोबारा कोशिश करें',
  trySearchingForSomethingElse: 'कुछ और के लिए खोजने की कोशिश करें.',
  updatePreferences: 'प्राथमिकताएं अपडेट करें',
  wantIt: 'इसे चाहता हूं',
  weAreBusySelfImproving: 'हम खुद को सुधारने में व्यस्त हैं',
  weAreVerySeriousAboutPrivacy:
    'हम आपके डेटा को लेकर गंभीर है, और हम आपका डेटा किसी तीसरे पक्ष को कभी नहीं बेचेंगे। कभी भी.',
  weDontSellYourData: 'हम आपका डेटा नहीं बेचते',
  weGotStuckOnOurWay: 'हम {{retailer}} के रास्ते में फंस गए!',
  weHitYouUp: 'इस स्टोर के लिए प्रोमो कोड मिलने पर हम आपसे संपर्क करेंगे।',
  weHustleForCoupons: 'हम कूपन के लिए ऊधम मचाते हैं',
  weUseBankLevelSecurity: 'हम बैंक-स्तर की सुरक्षा का उपयोग करते हैं',
  wellApplyCodesThatSaveYouMoney: 'हम आपको $$$ बचाने वाले कोड अप्लाई करेंगे।',
  wellTrackAndScanForCoupons:
    'हम कूपन को ट्रैक और स्कैन करेंगे ताकि आपको ऐसा न करना पड़े। <br/> (ठीक है, हम नहीं करेंगे, लेकिन हमारी एआई करेगी)',
  wellTrackAndScanForCouponsMobile: 'हम कूपन को ट्रैक और स्कैन करेंगे ताकि आपको ऐसा न करना पड़े।',
  wereAVerbNowLol: '(अब हम एक क्रिया हैं lol)',
  wereGoingShopping: 'हम शॉपिंग पर जा रहे हैं',
  wereHereToMakeItYours: 'हम यहां इसलिए हैं कि <1>#make_it_yours</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'आप हमारे सारे मनी सेविंग ईमेल्स को अनसबस्क्राइब क्यों करते हों जबकि आप सिर्फ उन ईमेल्स को बंद कर सकते हैं जो रिलेवेंट नहीं हैं?',
  withKarmaYouCanBuy: 'कर्मा से आप जो चाहें खरीद <br />सकते हैं और बैंक लेडी <br />को खुश रख सकते हैं।',
  withKarmaYourCash: 'कर्मा के साथ, आपकी नकदी आपके पास वापस आ जाती है जैसे कि कोई जादू, जादू का पैसा।',
  yesDisconnect: 'हां, डिसकनेक्ट करें',
  yesIDoThat: 'हां, मैं वह करता है',
  youHaveToReconnectToWithdraw: 'अपना कर्मा कैश निकालने के लिए आपको फिर से कनेक्ट करना होगा।',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'अपनी सूची साझा करने से पहले आपको आइटम सेव करने होंगे।',
  youReATen: 'आप 10 हैं और कर्मा से भुगतान करेंगे? ऑनलाइन खरीदारी करें और न्यूनतम संभव कीमत प्राप्त करें 👏 हर👏 बार।',
  youReAboutToMuteNumberItems: 'आप {{value}} आइटम के लिए नोटिफ़िकेशन म्यूट करने वाले हैं',
  yourCashWillComeBackToYou: 'कर्मा के साथ, आपकी नकदी आपके पास वापस आ जाती है जैसे कि कोई जादू, जादू का पैसा। ',
  yourInformationIsSecured:
    'आपकी सूचना की पूर्ण सुरक्षा के लिए उसे नवीनतम 256-बिट एन्क्रिप्शन के साथ सुरक्षित रखा गया है।',
  yourSearchCameUpEmpty: 'आपकी खोज खाली निकली',
  total: 'कुल',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'हमारे द्वारा {{email}} पर भेजे जाने वाले स्टेटस अपडेट पर नजर रखें।',
  psaDidYouKnow: 'पीएसए: क्या आप जानते हैं कि आप',
  addWishItemsFromAnyBrand:
    "किसी भी ब्रांड से 'इच्छा वाले' आइटम जोड़ सकते हैं और जब तक कि हम यह न कहें कि इसे प्राप्त करने का समय आ गया है, इंतजार कर सकते हैं।",
  capturePurchaseReadyShoppers:
    'अपने सबसे लोकप्रिय कूपन प्रदर्शित करके खरीदारी के लिए तैयार खरीदारों को आकर्षित कर सकते हैं।',
  gainFullFunnelInsights:
    'सहेजे गए प्रत्येक आइटम के साथ खरीदार के इरादे में पूर्ण-फनल अंतर्दृष्टि प्राप्त कर सकते हैं।',
  boostCartEngagement: 'हमारे रीयल-टाइम आइटम अपडेट के साथ कार्ट एंगेजमेंट बढ़ा सकते हैं।',
  promoteLongTermGrowth:
    'कर्मा पारिस्थितिकी तंत्र के भीतर अनुकूलित अभियानों के माध्यम से दीर्घकालिक विकास को बढ़ावा दे सकता है।',
  reachNewAudiences: 'आपके ब्रांड के अनुरूप विविध अभियानों के माध्यम से नए दर्शकों तक पहुंच सकते हैं',
  influencerCollaborations: 'इन्फ्लुएंसर के साथ सहयोग',
  teamUpWithTopInfluences:
    'शीर्ष इन्फ्लुएंसरों के साथ टीम बनाएं जो आपके ब्रांड की पहचान से सबसे अच्छी तरह मेल खाते हों।',
  socialPosts: 'सामाजिक पोस्ट',
  giveANewFollowing: 'पहले से तैयार, सामग्री-समृद्ध अभियानों से नया फॉलो करने वाले प्राप्त करें।',
  brandFocusedNewsletters: 'ब्रांड-केंद्रित न्यूजलेटर्स',
  letYourOffersShine: 'अत्यधिक प्रत्याशित क्यूरेटेड ईमेल में अपने ऑफर को जगमगाने दें।',
  driveTrafficWhenYouNeedIt:
    'हमारे खरीदारी योग्य होमपेज प्लेसमेंट के साथ ट्रैफिक को वहां लाएं जहां आपको इसकी आवश्यकता है',
  dashboardBanners: 'डैशबोर्ड बैनर',
  flauntYourLatestOfferings: 'आकर्षक दृश्यों के साथ अपनी नवीनतम ऑफरों को खुलकर दिखाएं।',
  dailyFeatured: 'रोज जो विशेष रुप से प्रदर्शित की गई',
  highlightDeals: 'उन सौदों को हाइलाइट करें जहां यह सबसे अधिक मायने रखता है।',
  dedicatedPopUps: 'समर्पित पॉप-अप',
  activelyServeYourPromos: 'सक्रिय रूप से अपने प्रोमो को दिखाएं ताकि उन्हें बिना देखे कोई ना बच सके।',
  createAnotherTouchpointForYourBrand: 'अपने ब्रांड के लिए एक और टचपॉइंट बनाएं',
  increaseEngagement: 'मोबाइल और वेब पर लक्षित पुश सूचनाओं के माध्यम से सहभागिता और रूपांतरण दर बढ़ाएं।',
  karmaIsOnlineShoppingButBetter: 'कर्मा ऑनलाइन शॉपिंग है, लेकिन बेहतर है।',
  transactionsNumber: '👉 {{count, number}}M+ लेनदेन',
  partnershipsReview4: 'यह आपकी इच्छा सूची बनाने और पैसे बचाने के लिए शानदार है!',
  partnershipsReviewAuthor4: 'एमिली स्मिथ',
  partnershipsReview5:
    'यह वास्तव में मेरे द्वारा डाउनलोड किया गया सबसे अच्छा एक्सटेंशन है। पैसे बचाने और एक ही समय में पैसा पाने के लिए यह सबसे अच्छा है!',
  partnershipsReviewAuthor5: 'सेलेना रोब्लोक्स',
  noNeedToSlideIntoOurDMs:
    'हमारे डीएम में स्लाइड करने की कोई जरूरत नहीं है (हालांकि आप कर सकते हैं 😏). आपको जो कुछ भी चाहिए उसके लिए हमारी टीम यहां है।',
  supportIssues: 'समर्थन के मुद्दे',
  adsAndPartnerships: 'विज्ञापन और साझेदारी',
  jobOpportunities: 'रोजगार के अवसर',
  publicRelations: 'जनसंपर्क',
  letsChat: 'आइए बात करें',
  earnings: 'कमाई',
  allTime: 'पूरा समय',
  ordersMayTakeHoursToAppear: 'ऑर्डर प्रदर्शित होने में {{value, number}} घंटे तक लग सकते हैं।',
  youHaventReceivedAnyPayoutsYet: 'आपको अभी तक कोई भुगतान नहीं मिला है ',
  alerts: 'अलर्ट',
  featuresReview1:
    'मैंने अपनी इच्छा सूची में स्नीकर्स की एक जोड़ी को ऐड किया है और सचमुच अगले दिन एक संदेश मिला कि इसपर 50% छूट था। आप लोगों से पहले मैंने कुछ भी कैसे खरीदा?',
  featuresReviewAuthor1: 'सैंड्रा सी मार्टिनेज, ऐप स्टोर समीक्षक',
  featuresReview2:
    'यह सही में सबसे अच्छा स्मार्ट शॉपिंग ऐप है और मैं कुछ अन्य को जानता हूं। मुझे वास्तव में ऐसा लगता है कि मैं सिस्टम को थोड़ा धोखा दे रहा हूं क्योंकि मैं वास्तविक पैसे बचा रहा हूं! मैं हमेशा पूरा भुगतान करता था और ठगा हुआ महसूस करता था',
  featuresReviewAuthor2: 'गर्टी डी. एकरमैन, ऐप स्टोर समीक्षक',
  featuresReview3:
    "मैं एक नया लैपटॉप खरीद रहा था जिस पर पहले से ही 25% की छूट थी। जब मैंने 'कर्मा से भुगतान' पर दबाया, तो मैंने देखा कि कुल योग और भी कम था। ऐसा हर बार होता है जब मैं ऐप से भुगतान करता हूं",
  featuresReviewAuthor3: 'आइरीन जे कॉक्स, ऐप स्टोर समीक्षक',
  downloadNow: 'अभी डाउनलोड करें',
  wannaBeDeskBuddies: 'डेस्क वाला दोस्त बनना चाहते हैं?',
  seeOpenPositions: 'मौजूद पद देखें',
  weLiveAndSwearBy: 'हम जीते हैं और कसम खाते हैं ...',
  goodKarma: 'अच्छे कर्मा की',
  weBelieveThatGoodVibes: 'हमारा मानना है कि ~अच्छा वाइब्स~ एक जीवन शैली है और हम इसके साथ बने हुए हैं।',
  beingTrue: 'सच होना',
  theresNoUSvsU: 'कोई यूएस बनाम यू नहीं है। हमारी टेबल हमेशा गोल होती हैं और हम आपके लिए एक सीट बचा रहे हैं।',
  passion: 'उत्साह',
  weHaveThatFireYouKnow:
    'हमारे पास वह आग है, पता है? जो हम करते हैं उसके लिए प्रेरणा, दृढ़ता और प्यार की जरूरत होती है।',
  entrepreneurship: 'उद्यमिता',
  weWantItWeGetIt:
    'हम इसे चाहते हैं - हम इसे प्राप्त करते हैं। हमारे पास अंतहीन बड़े आकार पर बर्बाद करने का समय नहीं है। हम जो चाहते हैं उसके लिए आगे बढ़ते हैं।',
  mutuality: 'पारस्परिकता',
  wereMadeUpOfAwesomePeople:
    'हम अद्भुत लोगों से बने हैं जो समझते हैं कि टीम वर्क पर्याप्त नहीं है। हम वास्तव में एक दूसरे की परवाह करते हैं।',
  buildCustomerLoyalty: 'तत्काल कैशबैक से ग्राहक के प्रति वफादारी बनाएं',
  itemSaving: 'आइटम की बचत',
  itemUpdates: 'आइटम का अपडेट',
  backInStockAlerts: 'स्टॉक में वापस',
  lowInStockAlerts: 'स्टॉक में कम',
  storePromotions: 'स्टोर का प्रचार',
  exclusive: 'विशिष्ट',
  couponCopied: 'कूपन कॉपी हो गया',
  completedDesc:
    'स्टोर ने आपके ऑर्डर की पुष्टि कर दी है। कमाए गए किसी भी कर्मा कैश को खरीदारी की तारीख से {{days, number}} दिन और आपके द्वारा न्यूनतम {{currency}}{{amount, number}} तक पहुंचने के बाद ही निकाला जा सकता है।',
  notQualifiedDesc: 'आपके द्वारा ऑर्डर किए गए आइटम पर अतिरिक्त प्रतिबंध लागू हो सकते हैं।',
  pendingDesc:
    'स्टोर को आपका ऑर्डर मिल गया है। स्टोर की रिटर्न नीति के आधार पर, यह {{days, number}} दिनों तक के लिए लंबित दिखाई दे सकता है।',
  cancelledDesc:
    'स्टोर ने हमें सूचित किया है कि आपका ऑर्डर या तो रद्द कर दिया गया था या पूर्ण रूप से रिफंड दे दिया गया था।',
  downloadNowItsFree: 'अभी डाउनलोड करें, यह मुफ्त है',
  weLiveToMakeOurShoppersHappy: 'हम अपने खरीदारों को खुश करने के लिए हैं',
  moneySavedForYou: 'आपके लिए पैसे बचाए गए',
  happyShoppers2: 'खुश खरीदार',
  starReviews2: '{{value, number}} स्टार रिव्यू',
  storesWorldwide2: 'दुनिया भर में स्टोर',
  whyYouWill: 'आप क्यों (इस्तेमाल) करेंगे',
  it: 'यह',
  multiBrandWishLists:
    'मल्टी-ब्रांड विश सूची? लेजिट चिल्स। किसी भी ब्रांड से अपनी पसंद की कोई चीज खोजें, उसे अपनी कर्मा सूची में जोड़ें, और तब तक इंतजार करें जब तक कि हम यह न कहें कि उन्हें प्राप्त करने का समय आ गया है।',
  addKarmaToYourMobile: '<br />हमारा ऐप डाउनलोड करें <br />और लें <br />कर्मा टू-गो',
  appStoreReview1:
    'मैंने अपनी सूची में एक आईशैडो पैलेट जोड़ा और अगले दिन एक सूचना मिली कि इसपर 50% की छूट है। आपका धन्यवाद!!',
  appStoreReview2: 'यह तथ्य कि मेरी पॉकेट में सबसे कम कीमत होती है, बहुत खतरनाक है। लेकिन मुझे यह पसंद भी है',
  appStoreReview3: 'बस की प्रतीक्षा करते समय नए हेडफोन पर सबसे कम कीमत अर्जित की। यह बहुत आसान है',
  appStoreReviewBy: 'ऐप स्टोर की समीक्षा: {{नाम}} द्वारा',
  yourInfoSafeWithUs: 'आपकी जानकारी हमारे पास सुरक्षित है',
  whyGiveOutYourCCDeets: 'जब आप इसे एक ही स्थान पर रख सकते हैं तो अपना सीसी का विवरण क्यों दें?',
  partyForOne: '1 के लिए पार्टी',
  wellNeverSellYourDataToAnyThirdParties:
    'हम आपका डेटा कभी भी किसी तीसरे पक्ष को नहीं बेचेंगे, पूर्ण विराम। बैंक स्तर की सुरक्षा के साथ, आपका डेटा ताक-झांक करने वाली नजरों से सुरक्षित है।',
  buyWithoutATrace: 'बिना ट्रेस के खरीदें',
  weWontLetAnyoneTrackYourShoppingHabits:
    'हम किसी को भी आपकी खरीदारी की आदतों पर नजर नहीं रखने देंगे। यहां कोई निर्णय नहीं है।',
  spotSomethingYouLoveFromAnyBrand:
    'किसी भी ब्रांड से अपनी पसंद की कोई चीज खोजें, उसे अपनी कर्मा सूची में जोड़ें, और तब तक इंतजार करें जब तक कि हम यह न कहें कि उन्हें प्राप्त करने का समय आ गया है।',
  needIt: 'इसकी जरूरत है',
  appStoreReviewer: 'ऐप स्टोर समीक्षक',
  appStoreReview4:
    'मैं इस ऐप पर बहुत भरोसा करता/करती हूं ताकि मैं अपनी सभी "इच्छित" वस्तुओं को एक ही स्थान पर ट्रैक कर सकूं ताकि उन्हें सर्वोत्तम संभव मूल्य पर प्राप्त कर लूं।',
  appStoreReview5: 'इस ऐप ने मुझे बहुत पैसा बचाने में मदद की है।',
  wishlist: 'इच्छा सूची',
  getTheLowestPriceAtAllYourFavoriteBrands: 'अपने सभी पसंदीदा ब्रांडों पर सबसे कम कीमत प्राप्त करें।',
  getTheAppForFree: 'ऐप को मुफ्त में प्राप्त करें',
  addKarmaSpaced: 'कर्मा को <br />अपने डेस्कटॉप से जोड़ें<br />ताकि आप काम के घंटों के दौरान खरीदारी कर सकें।<br />',
  spotSomethingYouLoveFromAnywhere:
    'कहीं से अपनी पसंद की कोई चीज खोजें, उसे अपनी कर्मा सूची में जोड़ें, और तब तक इंतजार करें जब तक कि हम यह न कहें कि उसे प्राप्त करने का समय आ गया है।',
  getCouponsCashbackWithPeriod:
    'कूपन, कैश बैक और कीमत में गिरावट के अलर्ट प्राप्त करें।<br />हमेशा सबसे कम कीमत चुकाएं।',
  getCouponsCashbackWithAnd:
    'कूपन, कैश बैक, कीमतों में गिरावट के अलर्ट प्राप्त करें<br />और हमेशा सबसे कम कीमत का भुगतान करें।',
  clickTheLoginLinkFromYourPhone: 'कर्मा ऐप तक पहुंचने के लिए अपने फोन से लॉगिन लिंक पर क्लिक करें।',
  getItOn: 'इसे शुरू करें',
  downloadOnThe: 'पर डाउनलोड करें',
  likeShoppingFromYourComputer: 'अपने कंप्यूटर से खरीदारी करना पसंद करते हैं?',
  getTheKarmaExtensionToShopEvenBetter: 'और भी बेहतर खरीदारी के लिए कर्मा एक्सटेंशन प्राप्त करें।',
  headerMobileLogin: 'कर्मा | इंस्टॉल करें',
  noFomoHereMobile:
    "यहां कोई बड़ा मिस नहीं होगा। जब आपकी 'विशिज' की कीमत में गिरावट आएगी या स्टॉक वापस आएगा तो हम आपको एक आवाज देंगे।",
  withKarmaYourCashMobile: 'कर्मा के साथ, आपकी नकदी आपके पास वापस आ जाती है जैसे कि कोई जादू, जादू का पैसा।',
  spotSomethigYouLoveMobile:
    'कहीं से अपनी पसंद की कोई चीज खोजें, उसे अपनी कर्मा सूची में जोड़ें, और तब तक इंतजार करें, जब तक कि हम यह न कहें कि इसे प्राप्त करने का समय आ गया है।',
  wellTrackAndScanForCouponsMobile2:
    'हम कूपन को ट्रैक और स्कैन करेंगे ताकि आपको ऐसा न करना पड़े।  (ठीक है, हम नहीं करेंगे, लेकिन हमारी एआई करेगी)',
  weHustle: 'हम ऊधम मचाते हैं',
  forCoupons: '<1>कूपनों</1> के लिए',
  theMotherOfAll: 'सबकी माता',
  wishlists2: 'इच्छा सूची',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by finding the best benefits and rewards at checkout',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOn: 'Enable Karma on Safari',
  toAccessOurCouponScanner: 'to access our coupon scanner',
  alwaysAllowOnSites: '<0>Always allow</0> on all sites to get\naccess to the coupon scanner',
  dontSeeReview: 'I don’t see a “Review” button',
  iosOnboardingFlowYouNeedToUseSafari: 'You need to be in Safari to use the Karma extension',
  iosOnboardingFlowCopyTheLink: 'Copy the link below and paste it in Safari to continue.',
  iosOnboardingCopyLink: 'Copy link',
  iosOnboardingLinkCopied: 'Link copied',
  tapExtensionThenSelect: 'extension, then <1>select both middle options</1>',
  alwaysAllow: 'Always Allow',
  alwaysAllowOnEveryWebsite: 'Always Allow on Every Website',
  clickInTheAddressBar: 'Click<1></1>or',
  activate: 'Activate',
  grantAccess: 'Grant access',
  sweOnboardingStep1_1_1: 'Tap <1></1> or',
  sweOnboardingStep1_1_2: 'in the address bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the address bar',
  sweOnboardingStep1_2: 'Select <1>“Manage Extensions”<1/>',
  sweOnboardingStep1_3: 'Turn on <1>“Karma”</1>',
  sweOnboardingStep2_1_old: 'Tap the <1>A</1><2>A</2> in the address bar again',
  sweOnboardingStep2_2_old: 'Tap the <1><2></2></1> extension, then <4>select both middle options</4>',
  sweOnboardingStep2_3_old: 'Select “Always allow”',
  sweOnboardingStep2_4_old_1: 'Select “Always allow”',
  sweOnboardingStep2_4_old_2: '“On every Site”',

  sweOnboardingStep2_5_old_1: 'Go back to the',
  sweOnboardingStep2_5_old_2: 'Karma app',
  sweOnboardingStep2_1: 'Tap <1>“Review”</1> in the top right corner, then <3>select both middle options</3>',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
  yourDataHasBeenRemoved: 'Your data has been successfully removed.',
  confirmationCode: 'Confirmation code: {{code}}',
  startOnlineShopping: 'Start online shopping to see Karma in action',
  trendingOffers: 'Trending Offers',
  useCode: 'Use code: {{code}}',
  getDeal: 'Get Deal',
  buyNow: 'Buy now',
  trendingCoupons: 'Trending Coupons',
  searchResultsFor: 'Search Results for "{{search}}"',
  trendingItems: 'Trending Items',
  curateSmartWishlists: 'Curate Smart wishlists',
  karmaWillLetYouKnow: 'Karma will let you know when the items you want go on sale or are back in stock',
  saveEarnAtCheckout: 'Save & Earn at checkout',
  weApplyBestCoupon:
    'We’ll automatically apply the best coupon codes on the web at checkout & reward for your purchase on 1000’s of stores',
  welcomeTo: 'Welcome to',
  youAreOneStepCloser:
    'You’re one step closer to saving money\nand shopping smarter.\nGo to your favorite store and we’ll see you there!',
  startBrowsing: 'Start browsing',
  scanToGetApp: 'Scan to get the app',
  unlockYourCreditCardsBenefits: 'Unlock your credit cards benefits',
  easilyCompareBetweenCreditCards:
    'Easily compare between the credit cards rewards to choose the best one to use at checkout',
};
